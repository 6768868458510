<template>
    <div class="d-flex w-100" style="overflow-x: auto; overflow-y: visible;">
        <!-- <font-awesome-icon class="intermediate-point" icon="map-pin"/> -->
        <div class="w-75 d-flex"
            v-bind:class="{'intermediate-point-complete':selectedPoint.Nombre=='INICIO', 'intermediate-point-incomplete':selectedPoint.Nombre!='INICIO'}">
            <div class="flex-colum mx-auto justify-content-center hoverable" 
                v-on:click="changeSelectedPoint({'Nombre':'INICIO', 'PI':'INICIO'})">
                <div class="border rounded px-2 py-1 text-important mb-1 mx-1 text-nowrap" v-bind:class="{'point-selected':selectedPoint.Nombre=='INICIO'}"
                    style="border-color: #fe8500 !important; border-width: medium !important; font-size:x-small;">
                    INICIO
                </div>
                <font-awesome-icon icon="map-marker-alt"  class="w-100 mx-auto mb-1" 
                    style="color:#f80606" size="lg"/>
            </div>
        </div>

        <div v-for="(controlPoint, index) in controlPoints" :key="index" class="w-75 hoverable" 
            v-bind:class="{'intermediate-point-complete':selectedPoint==controlPoint, 'intermediate-point-incomplete':selectedPoint!=controlPoint}">
            
            <div class="flex-colum mx-auto justify-content-center" v-on:click="changeSelectedPoint(controlPoint, index)">
                <div class="border rounded px-2 py-1 text-important mb-1 mx-2 text-nowrap" v-bind:class="{'point-selected':selectedPoint==controlPoint}"
                    style="border-color: #fe8500 !important; border-width: medium !important; font-size:x-small">
                    {{controlPoint.Nombre}}
                </div>
                <font-awesome-icon icon="map-marker-alt"  class="w-100 mx-auto mb-1" 
                    style="color:#f80606" size="lg"/>
            </div>
        </div>

        <div class="intermediate-point-incomplete w-75 d-flex"
            v-bind:class="{'intermediate-point-complete':selectedPoint.Nombre=='META', 'intermediate-point-incomplete':selectedPoint.Nombre!='META'}">
            <div class="flex-colum mx-auto justify-content-center hoverable" v-on:click="changeSelectedPoint({'Nombre':'META', 'PI':'META'})">
                <div class="border rounded px-2 py-1 text-important mb-1" v-bind:class="{'point-selected':selectedPoint.Nombre=='META'}"
                    style="border-color: #fe8500 !important; border-width: medium !important; font-size:x-small">
                    META
                </div>
                <font-awesome-icon icon="map-marker-alt"  class="w-100 mx-auto mb-1" 
                    style="color:#f80606" size="lg"/>
            </div>
            
        </div>
    </div>
</template>

<script>

export default {
    name:'PointSelector',
    props:{
        // controlPoints: Array
        initPoint: Object
    },
    data(){
        return{
            selectedPoint: this.initPoint,
        }
    },
    methods:{
        
        changeSelectedPoint(controlPoint){
            let $vm = this;
            $vm.selectedPoint = controlPoint;
            $vm.$emit('pointClick', controlPoint);
        }
    },
    computed: {
        
        controlPoints() {
            let $vm = this;
            
            let tipoDeCarrera = $vm.$store.getters.tipo;
            let _controlPoints = [];

            if(tipoDeCarrera == 'Puntos Intermedios'){
                _controlPoints = $vm.$store.getters.puntosIntermedios;
            }
            else if(tipoDeCarrera == 'Vueltas'){
                console.log('Carrera de vueltas');
                _controlPoints = $vm.$store.getters.vueltas;
            }

            // console.log(_controlPoints);
            // console.log(tipoDeCarrera);
            return _controlPoints;
        }
    },
    setup() {
        
    },
    mounted(){
        let $vm = this;
        // console.log($vm.controlPoints);
    }
}
</script>

<style scoped>
    .intermediate-point-incomplete{
        position: relative;
        z-index: 0;
    }
    .intermediate-point-incomplete::before{
        content: "";
        position: absolute;
        top: 95%;
        width: 98%;
        height: 2px;
        /* background-color: pink; */
        border-top: 2px dotted;
        color: #f80606;
        z-index: -1;
    }
    .intermediate-point-complete{
        position: relative;
        z-index: 0;
    }
    .intermediate-point-complete::before{
        content: "";
        position: absolute;
        top: 95%;
        width: 98%;
        height: 2px;
        background-color: #f80606;
        z-index: -1;
    }
    .point-selected{
        background-color: #fe8500;
        color: whitesmoke;
        font-size: medium !important;
    }
</style>

