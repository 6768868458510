<template>
  <div class="home px-1 container">
    
    <div class="mt-2">
      
      <b-nav content-class="mt-4">
        <b-nav-item class="d-none" active>Todas</b-nav-item>
        <b-nav-item-dropdown class="d-none" text="Tipo">
          <b-dropdown-item>Bici</b-dropdown-item>
          <b-dropdown-item>A pie</b-dropdown-item>
          <b-dropdown-item>Trip</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown class="d-none" text="Estado">
          <b-dropdown-item>En marcha</b-dropdown-item>
          <b-dropdown-item>Finalizada</b-dropdown-item>
          <b-dropdown-item>Próxima</b-dropdown-item>
        </b-nav-item-dropdown>

        <div class="input-group rounded w-50 ms-auto mt-2 shadow-1 mb-2">
          <input type="search" class="form-control rounded" placeholder="Buscar carrera..." aria-label="Search"
            aria-describedby="search-addon" v-model="carreraSearch" v-on:change="carreraFilter"/>
          
          <span class="input-group-text bg-alert border-0" style="" id="search-addon">
            <font-awesome-icon icon="search"/>
          </span>
        </div>
      </b-nav>

      <b-table hover id="carreras-table" 
          :items="carreras"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          @row-clicked="carreraSelected"
          thead-class="crono-table-header"
          :filter="carreraCriteria"
          :filter-function="filterTable"
          :responsive="true"
          small>
          
          <template #head(Nombre) variant="secondary">
              <div class="d-flex justify-content-left crono-table-header">
                  <h5 class="table-header-text ms-3">Carrera</h5>
              </div>
          </template>

          <template #head(Fecha) variant="secondary">
              <div class="d-flex justify-content-center crono-table-header">
                  <h5 class="table-header-text">Fecha</h5>
              </div>
          </template>

          <template #head(Tipo) >
              <div class="d-flex justify-content-center crono-table-header">
                  <h5 class="table-header-text">Tipo</h5>
              </div>
          </template>

          <template #cell(Fecha)="data">
            <div class="d-flex justify-content-center">
              <p class="small mx-auto text-wrap">{{data.value.split(" ")[0]}}</p>
            </div>
          </template>

          <template #cell(Nombre)="data">
            <div>
              <p class="small mx-auto text-wrap">{{data.value}}</p>
            </div>
          </template>

          <template #cell(Tipo)="data">
              <div class="d-flex justify-content-center">
                  <p class="mx-auto text-wrap small">{{data.value}}</p>
              </div>
          </template>

          <template #head(Estado)>
              <div class="d-flex justify-content-center crono-table-header">
                  <h5 class="table-header-text">Estado</h5>
              </div>
          </template>

          <template #cell(Estado)="data">
            <div class="d-flex align-content-center">
                <p class="mx-auto carrera-status-info small" v-bind:class="{'finalizada':data.value == 'Finalizada', 
                'corriendo':data.value == 'Corriendo'}">{{data.value}}</p>
            </div>
          </template>
      </b-table>
      
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="carreras-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import JSZip from 'jszip';

export default {
  name: 'Home',
  components: {

  },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      sortBy: 'Fecha',
      sortDesc: false,
      criteriaFilter: '',
      carreraCriteria: '',
      // fields: ['Nombre', 'Estado', 'Edición', 'Fecha', 'Distancia', 'Tipo'],
      fields: [
        {key:'Nombre', isRowHeader: true, class:'crono-table-header'},
        {key:'Fecha', isRowHeader: true, class:'crono-table-header'},
        {key:'Estado', isRowHeader: true}, 
        {key:'Tipo', isRowHeader:true}
      ],
      carreras: [
        
      ],
      carrerasInfo: [],
      carreraSearch: '',
    }
  },
  methods: {
    
    /**Obtenemos el listado de todas las carreras disponibles */
    getCarreras: function(){
      console.log("Se están consultando las carreras");
      axios.get("/data/index.php", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        }
      })
      .then( result =>{
        let $vm = this;
        $vm.carrerasInfo = result.data;
        // console.log($vm.carrerasInfo);
        console.log("Se recibió la información del servidor");
        $vm.carrerasInfo.forEach( element=> {
          let kms = parseInt( element.DistanciaMetrosTotalT01 )/1000.0;
          let fechaDeCarrera;

          if("FechaCarreraT01" in element){
            fechaDeCarrera = element.FechaCarreraT01;
          }
          else if("DiaHoraInicioT01" in element){
            fechaDeCarrera = element.DiaHoraInicioT01;
          }
          
          $vm.carreras.push({Nombre: element.NombreCarreraT01, 
            Estado: element.EstadoCarrera,
            Edicion: element.EdicionCarreraT01,
            // Fecha: element.DiaHoraInicioT01,
            // Fecha: element.Fecha,
            Fecha: fechaDeCarrera,
            Distancia: kms + 'kms',
            'Tipo': element.Modalidad,
            TS: element.TS,
            dir: element.dir })
          // console.log(element.NombreCarreraT01)
        })

        $vm.carreras.sort(function(a,b){
          // let date1 = new Date( parseInt(a.TS) );
          // let date2 = new Date( parseInt(b.TS) );

          let splittedDateA = a.Fecha.split(' ');
          let splittedDateB = b.Fecha.split(' ');

          let originalDateA = splittedDateA[0];
          let originalDateB = splittedDateB[0];

          let splittedA = originalDateA.split('/');
          let splittedB = originalDateB.split('/');

          let yearA = splittedA[2];
          let yearB = splittedB[2];

          let monthA = splittedA[1];
          let monthB = splittedB[1];
          
          let dayA = splittedA[0];
          let dayB = splittedB[0];

          let formatedA = yearA + '/' + monthA + '/' + dayA + ' ' + splittedDateA[1];

          let formatedB = yearB + '/' + monthB + '/' + dayB + ' ' + splittedDateB[1];

          let date1 = Date.parse(formatedA);
          let date2 = Date.parse(formatedB);

          if(isNaN(date1) ){
            // console.log("Es un NaN");
            date1 = 0;
          }
          if(isNaN(date2)){
            // console.log("Es un NaN");
            date2 = 0;
          }

          if(date1 < date2){
            return 1;
          }
          if(date1 > date2){
            return -1;
          }
          return 0;
        });

        // console.log($vm.carreras);
      })
      .catch( err =>{
        console.log(err);
      })
    },
    carreraSelected: function(record, index){
      let $vm = this;
      console.log(record);
      console.log(index);
      // $vm.getCarrera(record.dir, record.TS)
      let distancia = record.Distancia.split('kms');

      distancia = parseInt(distancia[0]);
      console.log(distancia);
      $vm.$store.commit('change', record.Nombre);
      $vm.$store.commit('changeDistancia', distancia);
      $vm.$router.push('/carrera/' + record.dir);
    },
    filterTable: function(row){
      let $vm = this;
      // console.log($vm.carreraCriteria);
      if(row.Nombre.includes($vm.carreraCriteria)){
        return true;
      }
      else{
        return false;
      }
    },
    carreraFilter(){
      let $vm = this;
      $vm.carreraCriteria = $vm.carreraSearch.toUpperCase();
      console.log($vm.carreraCriteria);
    }
  },
  created(){
    let $vm = this;
    $vm.getCarreras();
    $vm.$store.commit('change', 'Sistema de resultados en tiempo real')
  },
  computed: {
    rows() {
        return this.carreras.length
      }
  }
}
</script>
