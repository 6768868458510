import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faRunning, faBiking, faSwimmer, faSkiing, faStopwatch20, faTrophy, faStar,
    faMale, faFemale, faAward, faSearch, faMapPin, faMapMarkerAlt, faFileExport, faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { store } from './store/store'
import VueMobileDetection from 'vue-mobile-detection';

import './style.css';

library.add(faRunning, faBiking, faSwimmer, faSkiing, faStopwatch20, faFileExport, faStar,
  faTrophy, faMale, faFemale, faAward, faSearch, faMapPin, faMapMarkerAlt, faFileExcel);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMobileDetection);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
