<template>
    <div class="home px-0">
        
        <div class="d-flex px-0 text-center no-selected-view">
            <div class="border-left border-right d-flex hoverable" v-on:click="setResultsView" style="border-right-style:solid; border-color: rgba(0,0,0,0.15)">
                <h6 class="my-auto text-important small"
                    v-bind:class="{'selected-view':viewType=='Resultados', 'no-selected-view':viewType!='Resultados'}" >RESULTADOS</h6>
            </div>
            
            <div class="border-left border-right d-flex hoverable" v-on:click="setParticipantesView" style="border-right-style:solid; border-color: rgba(0,0,0,0.15)">
                <h6 class="my-auto text-important small"
                    v-bind:class="{'selected-view':viewType=='Participantes', 'no-selected-view':viewType!='Participantes'}">PARTICIPANTES</h6>
            </div>
            
            <div class="border-left border-right d-flex hoverable" v-on:click="setCabezaView" style="border-right-style:solid; border-color: rgba(0,0,0,0.15)">
                <h6 class="my-auto text-important small text-nowrap" 
                    v-bind:class="{'selected-view':viewType=='Cabeza', 'no-selected-view':viewType!='Cabeza' }">CABEZA CARRERA</h6>
            </div>

            <div class="w-100 no-selected-view">
                
            </div>
        </div>
        
        <div class="container-fluid px-1 mx-0">
        
        
        <div class="bg-light d-flex py-2 px-0 flex-wrap">
            
            <div class="input-group mb-2">
                <b-form-input type="search" class="ms-2 w-75"
                    placeholder="Nombre, Dorsal..." 
                    v-model="participanteSearch" v-on:input="participanteFilter">
                </b-form-input>
                <span class="input-group-text bg-alert border-0" style="" id="search-addon">
                    <font-awesome-icon icon="search"/>
                </span>
            </div>

            <div class="d-flex flex-column w-25 me-4 ms-2 mt-2 mt-md-0">
                <label class="ms-2 my-auto mb-1">Equipo</label>
                <select type="dropdown" id="equipo-filter" v-model="equipoSelected"
                    class="my-auto me-4 w-100" v-on:change="teamFilter">
                    <option v-for="(equipo, index) in equipos" :key="index">{{equipo}}</option> 
                </select>
            </div>

            <div class="d-flex flex-column mt-2 mt-md-0 col-md-2">
                <label class="me-2 my-auto mb-1">Categoría</label>
                <select type="dropdown" id="equipo-filter" v-model="categoriaSelected" 
                    class="my-auto me-4" v-on:change="categoryFilter" >
                    <option v-for="(categoria, index) in categorias" :key="index">{{categoria}}</option>
                </select>
            </div>

            <!-- Filtros para elegir entre mostrar por genero -->
            <div class="d-flex mt-auto ms-2 mb-2">
                    <font-awesome-icon icon="male" class="my-auto me-2" role="button"
                        :size="menFilterStatus" v-on:click="filtroHombres"></font-awesome-icon>
                    <font-awesome-icon icon="female" class="my-auto ms-2" role="button"
                        :size="womenFilterStatus" v-on:click="filtroMujeres"></font-awesome-icon>
            </div>
            
        </div>

        <div class="px-2 py-2 mt-2">
            <point-selector ref="pointSelectorInput" :initPoint="queryPI" class="mb-3 py-0" @pointClick="handleResultsView" v-bind:class="{'d-none':viewType!='Resultados'}"></point-selector>
        </div>

        <h5 v-bind:class="{'d-none':favoriteDorsals.length == 0}">FAVORITOS</h5>

        <div v-if="$isMobile()" class="container-fluid d-flex flex-column mb-2">
            <div v-for="(participante, index) in favoriteItems" :key="index">
                <div class="d-flex my-0 py-0 border-bottom">

                    <h2 style="font-family:'Helvetica'; font-weight:600; color:#84343c" v-on:click="participanteSelected(participante)"
                        class="me-2" v-bind:class="{'d-none': (viewType == 'Resultados' && (selectedPoint == 'INICIO' || selectedPoint == 'META')) || 
                            (viewType == 'Participantes' || viewType == 'Cabeza')}">
                        {{index + 1}}.
                    </h2>
                    
                    <h2 style="font-family:'Helvetica'; font-weight:600; color:#84343c" v-on:click="participanteSelected(participante)"
                    v-bind:class="{'d-none':(participante.LlegoMeta && viewType == 'Cabeza') || 
                        (viewType=='Resultados' && ((selectedPoint=='META' && participante.value < 4 )|| selectedPoint=='INICIO') 
                        ||(selectedPoint != 'META' && selectedPoint != 'INICIO') ) || participante.Abandona == 'True' || viewType == 'Participantes'}">
                        {{participante.Pos_Cabeza}}.
                    </h2>

                    <h2 style="font-family:'Helvetica'; font-weight:600; color:#84343c" v-on:click="participanteSelected(participante)"
                    v-bind:class="{'d-none':(!participante.LlegoMeta && viewType == 'Cabeza' ) || 
                        (viewType != 'Cabeza')}">
                        {{participante.Pos}}.
                    </h2>

                    <div class="d-flex flex-column">
                        <h6 class="my-0 fw-bold" style="font-size: medium">
                            {{participante.Dorsal}}<span class="fw-bold" v-on:click="participanteSelected(participante)">.</span> <span v-on:click="participanteSelected(participante)"> {{participante.Nombre}} </span>
                            <font-awesome-icon :icon="['fas', 'star']" 
                                v-bind:class="{'no-favorite-mark':!participante.Favorito, 'favorite-mark':participante.Favorito}" size="xs"
                                v-on:click="addToFavorites(participante)" class="my-auto me-auto"></font-awesome-icon>
                        </h6>
                        <div class="small fw-bold mt-0 mb-1 py-0" v-on:click="participanteSelected(participante)">
                            <p style="color:#989898; font-size:xx-small; text-transform: uppercase;" class="my-0">
                                {{participante.Equipo}}
                            </p>
                        </div>

                        <div class="d-flex" v-bind:class="{'d-none':viewType=='Participantes' || (viewType == 'Resultados' && selectedPoint =='INICIO')}"
                            v-on:click="participanteSelected(participante)">
                            <h6 style="font-size:small;" class="small rounded fw-bold" v-bind:class="{'d-none':viewType == 'Cabeza'}">
                                {{participante.tiempoPI}} <span v-bind:class="{'d-none':viewType == 'Resultados'}"> - {{participante.NombrePI}}</span>
                            </h6>
                            <h6 style="font-size:small;" class="small rounded fw-bold" v-bind:class="{'d-none':viewType == 'Resultados'}">
                                {{participante.Tiempo_Final}} <span v-bind:class="{'d-none':viewType == 'Resultados'}"> - {{participante.NombrePI}}</span>
                            </h6>
                            <p v-bind:class="{'d-none':showExtraInfo}" style="color:#989898; font-size:xx-small; text-transform: uppercase;" class="my-0 ms-2 fw-bold">
                                {{msToTime(participante.Info[selectedPoint])}}
                            </p>
                        </div>

                        <div class="d-flex mb-1" v-bind:class="{'d-none':viewType!='Participantes'}" v-on:click="participanteSelected(participante)">
                            <p style="color:#989898; font-size:xx-small; text-transform: uppercase;" class="my-0 fw-bold">
                                {{participante.Categoria}}
                            </p>
                        </div>                        
                        <div class="d-flex mb-1" v-bind:class="{'d-none':viewType == 'Participantes'}" v-on:click="participanteSelected(participante)">
                                <p style="color:#989898; font-size:x-small; text-transform: uppercase;"
                                    v-bind:class="{'d-none':selectedPoint != 'META'}" class="me-1 fw-bold">
                                    {{participante.Info.PosCategoriaT05}}.
                                </p>
                                <p style="color:#989898; font-size:xx-small; text-transform: uppercase;" 
                                    v-bind:class="{'d-none':selectedPoint != 'META'}" class="my-0 fw-bold">
                                    {{participante.Categoria}}
                                </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-table v-else hover id="participantes-table" striped 
            :items="favoriteItems"
            :outlined="true"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"

            hoverable
            thead-class="crono-table-header"
            :responsive="true"
            v-bind:class="{'d-none': favoriteItems.length == 0}"
            small>

            <template #cell(Pos)="data" style="font-family:'Helvetica'">
                <div class="d-flex justify-content-center align-middle" v-on:click="participanteSelected(data.item, data.index)">
                    <font-awesome-layers 
                        v-bind:class="{'d-none': (data.value > 3 || data.value == 'x') || ( selectedPoint != 'META' && viewType == 'Resultados')}" class="mt-1">
                        <font-awesome-icon icon="trophy" style="color: rgba(255, 165, 0, 0.25);" size="2x" transform="shrink-5 left-5 down-2"/>
                        <font-awesome-layers-text
                        :value="data.value"
                        transform="shrink-0" 
                        style="font-weight:600;"/>
                    </font-awesome-layers>
                    <p class="mx-auto my-auto" 
                        v-bind:class="{'d-none':(viewType != 'Resultados' && 
                        (selectedPoint != 'META' || selectedPoint != 'INICIO')) || 
                        (viewType == 'Resultados' && selectedPoint == 'META')}">
                        {{data.index + 1}}
                    </p>
                    <p class="mx-auto my-auto" 
                    v-bind:class="{'d-none':(data.item.LlegoMeta && viewType == 'Cabeza' && data.value < 4) || 
                        (viewType=='Resultados' && ((selectedPoint=='META' && data.value < 4 )|| selectedPoint=='INICIO') 
                        ||(selectedPoint != 'META' && selectedPoint != 'INICIO') ) || data.item.Abandona == 'True'}">
                        {{data.item.Pos_Cabeza}}
                    </p>
                </div>
            </template>

            <template #cell(Split)="data">
                <div class="d-flex" style="font-family:'Helvetica'; font-weight:500; font-size:small;"
                    v-on:click="participanteSelected(data.item, data.index)">
                    <p class="mx-auto my-auto">{{msToTime(data.item.Info[selectedPoint])}}</p>
                </div>
            </template>
            
            <template #cell(Dorsal)="data">
                <div class="d-flex" style="font-family:'Helvetica'; font-weight:500;">
                    <p class="mx-auto my-auto" 
                        v-on:click="participanteSelected(data.item, data.index)">{{data.value}}</p>
                    <font-awesome-icon :icon="['fas', 'star']" 
                        v-bind:class="{'no-favorite-mark':!data.item.Favorito, 'favorite-mark':data.item.Favorito}" size="xs"
                        v-on:click="addToFavorites(data.item)" class="my-auto me-auto"></font-awesome-icon>
                </div>
            </template>

            <template #cell(Nombre)="data">
                <div class="d-flex" 
                    v-on:click="participanteSelected(data.item, data.index)">
                    <b class="text-info my-auto font-weight-bold">{{data.value}}</b>
                </div>
            </template>

            <template #cell(Tiempo_Final)="data">
                <div class="d-flex px-2" v-on:click="participanteSelected(data.item, data.index)">
                    <div class="w-100 my-1 d-flex rounded" style="font-family:'Helvetica'"
                        v-bind:class="{'bg-info': data.item.LlegoMeta, 'bg-dark': (!data.item.LlegoMeta && data.item.Abandona == 'False'), 'bg-danger': (data.item.Abandona == 'True' && !data.item.LlegoMeta)}">
                        <span class="text-white mx-auto" v-bind:class="{'d-none':viewType != 'Cabeza'}">{{data.value}}</span>
                        <span class="text-white mx-auto" v-bind:class="{'d-none':viewType == 'Cabeza'}">{{data.item.tiempoPI}}</span>
                    </div>   
                </div>
            </template>

            <template #cell(PI)="data">
                <div class="d-flex px-2" v-on:click="participanteSelected(data.item, data.index)">
                    <div class="d-flex mx-auto">
                        <p style="font-family:'Helvetica'" class="mx-auto my-auto text-nowrap">{{data.item.NombrePI}}</p>
                    </div>
                </div>
            </template>

            <template #cell(Categoria)="data">
                <div class="d-flex responsive-cell" v-on:click="participanteSelected(data.item, data.index)">
                    <p  style="font-family:'Helvetica'" class="mx-auto my-auto">{{data.item.Categoria}}</p>
                </div>
            </template>

            <template #cell(Pos_Categoria)="data">
                <div class="d-flex justify-content-center responsive-cell text-align-center"
                    v-on:click="participanteSelected(data.item, data.index)">
                    <font-awesome-layers class="mx-auto" 
                        v-bind:class="{'d-none': (data.value > 3 || data.value == 'x') || ( selectedPoint != 'META' && viewType == 'Resultados')}"
                        v-if="data.item.LlegoMeta && selectedPoint == 'META'">
                        <font-awesome-icon icon="award" :style="{'color': data.value === '1' ? '#AF9500' : data.value === '2' ? 'silver' : '#AD8A56' }" size="2x" transform="down-3 left-2"/>
                        <font-awesome-layers-text
                            :value="data.value"
                            class=""
                            transform="shrink-0" 
                            style="font-weight:950;"/>
                    </font-awesome-layers>
                    <div class="d-flex">
                        <p class="mx-auto my-auto text-align-center" 
                            style="font-family:'Helvetica'; text-align:center;" 
                            v-bind:class="{'d-none':(viewType != 'Resultados' && 
                        (selectedPoint != 'META' || selectedPoint != 'INICIO')) || 
                        (viewType == 'Resultados' && selectedPoint == 'META')}">
                            {{data.item.UltimaPosCat}}
                        </p>
                        <p class="mx-auto my-auto text-align-center" 
                            style="font-family:'Helvetica'; text-align:center;" 
                            v-bind:class="{'d-none':(data.item.LlegoMeta && viewType == 'Cabeza' && data.value < 4) || 
                        (viewType=='Resultados' && ((selectedPoint=='META' && data.value < 4 )|| selectedPoint=='INICIO') 
                        ||(selectedPoint != 'META' && selectedPoint != 'INICIO') ) || data.item.Abandona == 'True'}">
                            {{data.item.PosCatPI}}
                        </p>
                    </div>                    
                    <!-- <p class="mx-auto my-auto">{{data.value}}</p> -->
                </div>
            </template>

            <template #cell(Equipo)="data">
                <div class="d-flex responsive-cell" v-on:click="participanteSelected(data.item, data.index)">
                    <p class="my-auto" style="font-family:'Helvetica'">{{data.value}}</p>
                </div>
            </template>

            <template #cell(Sexo)="data">
                <div class="d-flex responsive-cell"
                 v-on:click="participanteSelected(data.item, data.index)">
                    <font-awesome-icon class="mx-auto" icon="male" v-bind:class="{'d-none':data.value != 'M'}" size="2x" transform="shrink-5"/>
                    <font-awesome-icon class="mx-auto" icon="female" v-bind:class="{'d-none':data.value != 'F'}" size="2x" transform="shrink-5"/>
                </div>
            </template>

            <template #cell(Pos_Equipo)="data">
                <div class="d-flex responsive-cell" v-on:click="participanteSelected(data.item, data.index)">
                    <font-awesome-layers class="mx-auto" 
                        v-bind:class="{'d-none': (data.value > 3 || data.value == 'x') || ( selectedPoint != 'META' && viewType == 'Resultados')}"
                        v-if="data.item.LlegoMeta && selectedPoint == 'META'">
                        <font-awesome-icon icon="award" :style="{'color': data.value === '1' ? '#AF9500' : data.value === '2' ? 'silver' : '#AD8A56' }" size="2x" transform="down-3 left-2"/>
                        <font-awesome-layers-text
                            :value="data.value"
                            class=""
                            transform="shrink-0" 
                            style="font-weight:950;"/>
                    </font-awesome-layers>

                    <p class="mx-auto my-auto" 
                        v-bind:class="{'d-none':(viewType != 'Resultados' && 
                        (selectedPoint != 'META' || selectedPoint != 'INICIO')) || 
                        (viewType == 'Resultados' && selectedPoint == 'META')}">
                        {{data.item.UltimaPosEq}}</p>
                    <p class="mx-auto my-auto" 
                        v-bind:class="{'d-none':(data.item.LlegoMeta && viewType == 'Cabeza' && data.value < 4) || 
                            (viewType=='Resultados' && ((selectedPoint=='META' && data.value < 4 )|| selectedPoint=='INICIO') 
                            ||(selectedPoint != 'META' && selectedPoint != 'INICIO') ) || data.item.Abandona == 'True'}">
                        {{data.item.UltimaPosEq}}</p>
                </div>
            </template>
        </b-table>

        <div class="px-2 mt-1 d-flex align-items-center">
            <p>
                <span class="border rounded px-2">{{tableItems.length}}</span> / 
                <span class="border rounded px-2"> {{total}} </span>
                <span class="ms-2 small py-auto">{{descripcionItems}}</span>
            </p>
            <!-- <div class="flex-column d-flex ms-auto mb-0 justify-content-center">
                <font-awesome-icon icon="file-excel" class="mt-0 mx-auto" size="lg"></font-awesome-icon>
                <p class="fw-bold small mx-auto">XLS</p>

                <download-excel class="flex-column d-flex ms-auto mb-0 justify-content-center">
                    <font-awesome-icon icon="file-excel" class="mt-0 mx-auto" size="lg"></font-awesome-icon>
                    <p class="fw-bold small mx-auto">XLS</p>
                </download-excel>
            </div> -->
            <json-excel role="button" class="flex-column d-flex ms-auto mb-0 justify-content-center" 
                data-toggle="tooltip"
                title="Descargar archivo"
                :data="tableItemsXLS"
                :name="carreraName"
                footer="cronomancha">
                <font-awesome-icon icon="file-excel" class="mt-0 mx-auto" size="lg"></font-awesome-icon>
                <p class="fw-bold small mx-auto">XLS</p>
            </json-excel>
        </div>
        
        <div v-if="$isMobile()"
            class="container-fluid d-flex flex-column"> 
            <div v-for="(participante, index) in _updatedTableItems" :key="index">
                <div class="d-flex my-0 py-0 border-bottom">
                    
                    <h2 style="font-family:'Helvetica'; font-weight:600; color:#84343c" v-on:click="participanteSelected(participante)"
                        class="me-2" v-bind:class="{'d-none': (viewType == 'Resultados' && (selectedPoint == 'INICIO' || selectedPoint == 'META')) || 
                            (viewType == 'Participantes' || viewType == 'Cabeza')}">
                        {{index + 1}}.
                    </h2>
                    
                    <h2 style="font-family:'Helvetica'; font-weight:600; color:#84343c" v-on:click="participanteSelected(participante)"
                    v-bind:class="{'d-none':(participante.LlegoMeta && viewType == 'Cabeza' ) || 
                        (viewType=='Resultados' && ((selectedPoint=='META' && participante.value < 4 )|| selectedPoint=='INICIO') 
                        ||(selectedPoint != 'META' && selectedPoint != 'INICIO') ) || participante.Abandona == 'True' || viewType == 'Participantes'} ">
                        {{participante.Pos_Cabeza}}.
                    </h2>

                    <h2 style="font-family:'Helvetica'; font-weight:600; color:#84343c" v-on:click="participanteSelected(participante)"
                    v-bind:class="{'d-none':(!participante.LlegoMeta && viewType == 'Cabeza' ) || 
                        (viewType != 'Cabeza')} ">
                        {{participante.Pos}}.
                    </h2>

                    <div class="d-flex flex-column">
                        <h6 class="my-0 fw-bold" style="font-size: medium">
                            {{participante.Dorsal}}<span class="fw-bold" v-on:click="participanteSelected(participante)">.</span> 
                            <span v-on:click="participanteSelected(participante)">{{participante.Nombre}}</span>
                            <font-awesome-icon :icon="['fas', 'star']" 
                            v-bind:class="{'no-favorite-mark':!participante.Favorito, 'favorite-mark':participante.Favorito}" size="xs"
                            v-on:click="addToFavorites(participante)" class="my-auto me-auto"></font-awesome-icon>
                        </h6>
                        
                        <div class="small fw-bold mt-0 mb-1 py-0" v-on:click="participanteSelected(participante)">
                            <p style="color:#989898; font-size:xx-small; text-transform: uppercase;" class="my-0">
                                {{participante.Equipo}}
                            </p>
                        </div>

                        <div class="d-flex" v-bind:class="{'d-none':viewType=='Participantes' || (viewType == 'Resultados' && selectedPoint =='INICIO')}" v-on:click="participanteSelected(participante)">
                            <h6 style="font-size:small;" class="small rounded fw-bold" v-bind:class="{'d-none':viewType == 'Cabeza'}">
                                {{participante.tiempoPI}} <span v-bind:class="{'d-none':viewType == 'Resultados'}"> - {{participante.NombrePI}}</span>
                            </h6>
                            <h6 style="font-size:small;" class="small rounded fw-bold" v-bind:class="{'d-none':viewType == 'Resultados'}">
                                {{participante.Tiempo_Final}} <span v-bind:class="{'d-none':viewType == 'Resultados'}"> - {{participante.NombrePI}}</span>
                            </h6>
                            <p v-bind:class="{'d-none':showExtraInfo}" style="color:#989898; font-size:xx-small; text-transform: uppercase;" class="my-0 ms-2 fw-bold">
                                {{msToTime(participante.Info[selectedPoint])}}
                            </p>
                        </div>

                        <div class="d-flex mb-1" v-bind:class="{'d-none':viewType!='Participantes'}"
                         v-on:click="participanteSelected(participante)">
                            <p style="color:#989898; font-size:xx-small; text-transform: uppercase;" class="my-0 fw-bold">
                                {{participante.Categoria}}
                            </p>
                        </div>                        
                        <div class="d-flex mb-1" v-bind:class="{'d-none':viewType == 'Participantes'}"
                            v-on:click="participanteSelected(participante)">

                                <p style="color:#989898; font-size:x-small; text-transform: uppercase;"
                                    v-bind:class="{'d-none':selectedPoint == 'META'}" class="me-1 fw-bold">
                                    {{participante.PosCatPI}}.
                                </p>

                                <p style="color:#989898; font-size:x-small; text-transform: uppercase;"
                                    v-bind:class="{'d-none':selectedPoint != 'META'}" class="me-1 fw-bold">
                                    {{participante.Info.PosCategoriaT05}}.
                                </p>
                                <p style="color:#989898; font-size:xx-small; text-transform: uppercase;" 
                                    class="my-0 fw-bold">
                                    {{participante.Categoria}}
                                </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <b-table v-else hover id="participantes-table" striped 
            :items="_updatedTableItems"
            :outlined="true"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            hoverable
            
            :filter="criteriaFilter"
            :filter-function="filterTable"
            thead-class="crono-table-header"
            :responsive="true"   
            small>

            <template #cell(Pos)="data" style="font-family:'Helvetica'">
                <div class="d-flex justify-content-center align-middle" v-on:click="participanteSelected(data.item, data.index)">
                    <font-awesome-layers 
                        v-bind:class="{'d-none': (data.value > 3 || data.value == 'x') ||  (viewType == 'Cabeza' && data.item.LlegoMeta == false) ||
                        ( selectedPoint != 'META' && viewType == 'Resultados')}" class="mt-1">
                        <font-awesome-icon icon="trophy" style="color: rgba(255, 165, 0, 0.25);" size="2x" transform="shrink-5 left-5 down-2"/>
                        <font-awesome-layers-text
                        :value="data.value"
                        transform="shrink-0" 
                        style="font-weight:600;"/>
                    </font-awesome-layers>
                    <p class="mx-auto my-auto" 
                        v-bind:class="{'d-none':(viewType != 'Resultados' && 
                        (selectedPoint != 'META' || selectedPoint != 'INICIO')) ||
                        (viewType == 'Resultados' && selectedPoint == 'META')}">
                        {{data.index + 1}}
                    </p>
                    <p class="mx-auto my-auto" 
                    v-bind:class="{'d-none':(data.item.LlegoMeta && viewType == 'Cabeza' && data.value < 4) || 
                        (viewType=='Resultados' && ((selectedPoint=='META' && data.value < 4 )|| selectedPoint=='INICIO') 
                        ||(selectedPoint != 'META' && selectedPoint != 'INICIO') ) || data.item.Abandona == 'True'}">
                        {{data.item.Pos_Cabeza}}
                    </p>
                    <!-- <p class="mx-auto my-auto" v-bind:class="{'d-none': (data.value < 4 || data.value == 'x')}">{{data.value}}</p> -->
                    <!-- <p class="mx-auto my-auto" 
                    v-bind:class="{'d-none':(data.item.LlegoMeta && viewType == 'Cabeza' && data.value < 4) || (viewType=='Resultados' && ((selectedPoint=='META' && data.value < 4 )|| selectedPoint=='INICIO')) || data.item.Abandona == 'True'} ">
                        {{data.item.Pos_Cabeza}}
                    </p> -->
                    <!-- <p>{{data}}</p> -->
                </div>
            </template>

            <template #cell(Split)="data">
                <div class="d-flex" style="font-family:'Helvetica'; font-weight:500; font-size:small;"
                    v-on:click="participanteSelected(data.item, data.index)">
                    <p class="mx-auto my-auto">{{msToTime(data.item.Info[selectedPoint])}} </p>
                </div>
            </template>
            
            <template #cell(Dorsal)="data">
                <div class="d-flex" style="font-family:'Helvetica'; font-weight:500;">
                    <p class="mx-auto my-auto" 
                        v-on:click="participanteSelected(data.item, data.index)">{{data.value}}</p>
                    <font-awesome-icon :icon="['fas', 'star']" 
                        v-bind:class="{'no-favorite-mark':!data.item.Favorito, 'favorite-mark':data.item.Favorito}" size="xs"
                        v-on:click="addToFavorites(data.item)" class="my-auto me-auto"></font-awesome-icon>
                </div>
            </template>

            <template #cell(Nombre)="data">
                <div class="d-flex" 
                    v-on:click="participanteSelected(data.item, data.index)">
                    <b class="text-info my-auto font-weight-bold">{{data.value}}</b>
                </div>
            </template>

            <template #cell(Tiempo_Final)="data">
                <div class="d-flex px-2" v-on:click="participanteSelected(data.item, data.index)">
                    <div class="w-100 my-1 d-flex rounded" style="font-family:'Helvetica'"
                        v-bind:class="{'bg-info': data.item.LlegoMeta, 'bg-dark': (!data.item.LlegoMeta && data.item.Abandona == 'False'), 'bg-danger': (data.item.Abandona == 'True' && !data.item.LlegoMeta)}">
                        <span class="text-white mx-auto" v-bind:class="{'d-none':viewType != 'Cabeza'}">{{data.value}}</span>
                        <span class="text-white mx-auto" v-bind:class="{'d-none':viewType == 'Cabeza'}">{{data.item.tiempoPI}}</span>
                    </div>   
                </div>
            </template>

            <template #cell(PI)="data">
                <div class="d-flex px-2" v-on:click="participanteSelected(data.item, data.index)">
                    <div class="d-flex mx-auto">
                        <p style="font-family:'Helvetica'" class="mx-auto my-auto text-nowrap">{{data.item.NombrePI}}</p>
                    </div>
                </div>
            </template>

            <template #cell(Categoria)="data">
                <div class="d-flex responsive-cell" v-on:click="participanteSelected(data.item, data.index)">
                    <p  style="font-family:'Helvetica'" class="mx-auto my-auto">{{data.item.Categoria}}</p>
                </div>
            </template>

            <template #cell(Pos_Categoria)="data">
                <div class="d-flex justify-content-center responsive-cell text-align-center"
                    v-on:click="participanteSelected(data.item, data.index)">
                    <font-awesome-layers class="mx-auto" 
                        v-bind:class="{'d-none': (data.value > 3 || data.value == 'x') || ( selectedPoint != 'META' && viewType == 'Resultados')}"
                        v-if="data.item.LlegoMeta && selectedPoint == 'META'">
                        <font-awesome-icon icon="award" :style="{'color': data.value === '1' ? '#AF9500' : data.value === '2' ? 'silver' : '#AD8A56' }" size="2x" transform="down-3 left-2"/>
                        <font-awesome-layers-text
                            :value="data.value"
                            class=""
                            transform="shrink-0" 
                            style="font-weight:950;"/>
                    </font-awesome-layers>
                    <div class="d-flex">
                        <p class="mx-auto my-auto text-align-center"  
                            style="font-family:'Helvetica'; text-align:center;" 
                            v-bind:class="{'d-none':(viewType != 'Resultados' && 
                            (selectedPoint != 'META' || selectedPoint != 'INICIO')) || 
                            (viewType == 'Resultados' && selectedPoint == 'META')}">
                            {{data.item.UltimaPosCat}}
                        </p>
                        <p class="mx-auto my-auto text-align-center" 
                            style="font-family:'Helvetica'; text-align:center;" 
                            v-bind:class="{'d-none':(data.item.LlegoMeta && viewType == 'Cabeza' && data.value < 4) || 
                        (viewType=='Resultados' && ((selectedPoint=='META' && data.value < 4 )|| selectedPoint=='INICIO') 
                        ||(selectedPoint != 'META' && selectedPoint != 'INICIO') ) || data.item.Abandona == 'True'}">
                        {{data.item.PosCatPI}}
                    </p>
                        
                    </div>
                    <!-- <div class="d-flex" v-bind:class="{'d-none': viewType != 'Cabeza' || ( viewType == 'Cabeza' && (data.value < 4 || data.value == 'x')) }">
                        <p>{{data.value}}</p>
                    </div> -->
                    <!-- <div class="d-flex">
                        <p>{{data.value}}</p>
                    </div> -->

                </div>
            </template>

            <template #cell(Equipo)="data">
                <div class="d-flex responsive-cell" v-on:click="participanteSelected(data.item, data.index)">
                    <p class="my-auto" style="font-family:'Helvetica'">{{data.value}}</p>
                </div>
            </template>

            <template #cell(Sexo)="data">
                <div class="d-flex responsive-cell"
                 v-on:click="participanteSelected(data.item, data.index)">
                    <font-awesome-icon class="mx-auto" icon="male" v-bind:class="{'d-none':data.value != 'M'}" size="2x" transform="shrink-5"/>
                    <font-awesome-icon class="mx-auto" icon="female" v-bind:class="{'d-none':data.value != 'F'}" size="2x" transform="shrink-5"/>
                </div>
            </template>

            <template #cell(Pos_Equipo)="data">
                <div class="d-flex responsive-cell" v-on:click="participanteSelected(data.item, data.index)">
                    <font-awesome-layers class="mx-auto" 
                        v-bind:class="{'d-none': (data.value > 3 || data.value == 'x') || ( selectedPoint != 'META' && viewType == 'Resultados')}"
                        v-if="data.item.LlegoMeta && selectedPoint == 'META'">
                        <font-awesome-icon icon="award" :style="{'color': data.value === '1' ? '#AF9500' : data.value === '2' ? 'silver' : '#AD8A56' }" size="2x" transform="down-3 left-2"/>
                        <font-awesome-layers-text
                            :value="data.value"
                            class=""
                            transform="shrink-0" 
                            style="font-weight:950;"/>
                    </font-awesome-layers>

                    <p class="mx-auto my-auto" 
                        v-bind:class="{'d-none':(viewType != 'Resultados' && 
                        (selectedPoint != 'META' || selectedPoint != 'INICIO')) || 
                        (viewType == 'Resultados' && selectedPoint == 'META')}">
                            {{data.item.UltimaPosEq}}</p>
                    <p class="mx-auto my-auto" 
                        v-bind:class="{'d-none':(data.item.LlegoMeta && viewType == 'Cabeza' && data.value < 4) || 
                        (viewType=='Resultados' && ((selectedPoint=='META' && data.value < 4 )|| selectedPoint=='INICIO') 
                        ||(selectedPoint != 'META' && selectedPoint != 'INICIO') ) || data.item.Abandona == 'True'}">
                        {{data.item.UltimaPosEq}}</p>
                </div>
            </template>
        </b-table>
        
        <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="participantes-table"
        ></b-pagination>
        </div>

        <b-modal ref="runner-info"
            size="xl"
            :hide-header="true"
            :header-bg-variant="'light'">
            <div class="px-0">
                <div class="d-block">
                    <div class="d-flex">
                        <div class="col">
                            <div class="mb-2">
                                <h5 class="my-auto">{{runner}}</h5>
                            </div>
                            <div class="d-flex flex-wrap">
                                <div class="flex-column col-2 col-sm-1 me-2">
                                    <small class="text-muted">Dorsal</small>
                                    <h6 class="">{{runnerDorsal}}</h6>
                                </div>
                                <div class="flex-column col-5 col-md-3 col-lg-2 px-1 me-2">
                                    <small class="text-muted">Modalidad</small>
                                    <h6 class="overflow-hidden"><small>{{$store.getters.modalidad}}</small></h6>
                                </div>
                                <div class="flex-column col-2">
                                    <small class="text-muted">Distancia</small>
                                    <h6 class=""><small>{{$store.getters.distancia / 1000}}km</small></h6>
                                </div>
                                <div class="flex-column col-sm-4 col-3 col-md-2 me-2">
                                    <small class="text-muted">Categoría</small>
                                    <h6 class=""><small>{{runnerCategory}}</small></h6>
                                </div>
                                <div class="flex-column col">
                                    <small class="text-muted">Equipo</small>
                                    <h6 class=""><small>{{runnerTeam}}</small></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-block mt-2">
                    <div class="d-flex flex-column">
                        <div class="w-10 crono-table-header py-2 d-flex justify-content-center">
                            <h6 class="my-auto table-header-text">RESULTADOS - {{carrera}}</h6> 
                        </div>
                        <div class="d-flex">
                            <div class="w-100">
                                <div class="row">
                                    <div class="col-2 d-flex flex-column">
                                        <small class="text-muted font-weight-light mx-auto" 
                                                style="font-weight:350 !important;">Posición</small>
                                        <div class="mx-auto d-flex">
                                            <span class="ms-auto" style="font-weight:500; font-size:1.5rem;" 
                                                v-bind:class="{'d-none': position=='x'}">{{position}}°</span>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-5 col-md-4 ms-lg-4 d-flex flex-column">
                                        <small class="text-muted font-weight-light mx-auto" 
                                                style="font-weight:350 !important;">Tiempo</small>
                                        <div class="mx-auto">
                                            <span v-bind:class="{'d-none': runnerTermino == false}"> {{runnerFinalTime}} </span>
                                        </div>
                                    </div>

                                    <div class="col-4 col-md-3 d-flex flex-column">
                                        <small class="text-muted font-weight-light mx-auto" 
                                                style="font-weight:350 !important;">Ritmo</small>
                                        <div class="mx-auto">
                                            <span v-bind:class="{'d-none': runnerTermino == false}"> {{runnerRitme}} </span>
                                        </div>
                                    </div>

                                    <div class="d-md-block d-none w-100"></div>

                                    <div class="col-6 col-md-4 mt-md-2 d-flex flex-column">
                                        <small class="text-muted font-weight-light mx-auto" 
                                                style="font-weight:350 !important;">{{tipoDeCarrera}}</small>
                                        <div class="mx-auto">
                                            <span v-bind:class="{'d-none': tipoDeCarrera != 'Puntos Intermedios'}">{{_puntosIntermedios}} PI</span>
                                            <span v-bind:class="{'d-none': tipoDeCarrera != 'Vueltas'}">{{vueltas}} Vueltas</span>
                                        </div>
                                    </div>

                                    <div class="col-6 col-md-4 mt-md-2 d-flex flex-column">
                                        <small class="text-muted font-weight-light mx-auto" 
                                                style="font-weight:350 !important;">Posición por categoría</small>
                                        <div class="mx-auto">
                                            <span v-bind:class="{'d-none': runnerTermino == false}"> {{runnerInfo.PosCategoriaT05}}° </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-block mt-4">
                    <div class="d-flex flex-column">
                        <div class="w-100 crono-table-header py-2 d-flex justify-content-center">
                            <h6 class="my-auto ml-3 table-header-text">PASOS</h6> 
                        </div>
                        <div class="d-flex">
                            <div class="w-100">
                                <b-table :fields="puntosfields" 
                                :items="puntosInfo"
                                :outlined="true">
                                    <template #head(Posicion)>
                                        <div class="d-flex justify-content-center">
                                            <span >Pos.</span>
                                        </div>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <template #modal-footer>
                
                <img class="crono-logo me-auto" alt="cronomancha logo" src="../assets/logo1.png">
                <b-button class="crono-table-header" @click="closeInfo">
                    ACEPTAR
                </b-button>
            </template>
                    
        </b-modal>
  </div>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'
import axios from 'axios';
import JSZip, { forEach } from 'jszip';
import pointSelector from '../components/PointSelector.vue'
import JsonExcel from "vue-json-excel";
import moment from 'moment';

export default {
    name: 'Carrera',
    components:{
        pointSelector, JsonExcel
    },
    props:{
        // carreraName: String,
    },
    data(){
        return{
            moment: moment,
            secondsCounter: 0,
            carreraName: "",
            runner: "",
            runnerMilisencods: "",
            runnerPosition: "",
            runnerDorsal: "",
            position: "",
            runnerCategory: "",
            runnerModalidad: "",
            runnerTeam: "",
            runnerFinalTime: "",
            runnerPositionTeam: "",
            runnerInfo: {},
            runnerTermino: false,
            vueltas: 1,
            sortBy: '',
            sortDesc: false,
            perPage: 50,
            currentPage: 1,
            estadoDeCarrera: "",
            tipoDeCarrera: "",
            carreraDir: "",
            viewType: "Resultados",
            descripcionItems: "PARTICIPANTES",
            fields: [
                {key: 'Pos', label:'POS.', sortable:true, tdClass: '', thClass:'table-header-text'},
                {key:'Dorsal', sortable:true, thClass:'table-header-text justify-content-center mx-auto'},  
                {key: 'Nombre', sortable:true, thClass:'table-header-text justify-content-center'}, 
                {key:'Tiempo_Final', label:"Tiempo", sortable:true, tdClass:'', thClass:'table-header-text justify-content-center'},
                {key:'Split', label: "Split", sortable: true, tdClass:'d-none', thClass:'d-none table-header-text'},
                {key:'PI', label:"PI", sortable:true, tdClass:'', thClass:'table-header-text justify-content-center'}, 
                {key:'Categoria', label:"Categoría", sortable:true, tdClass:'', thClass:'table-header-text justify-content-center'}, 
                {key: 'Pos_Categoria', label:"Pos. Categoría", sortable:true, tdClass:'', thClass:'table-header-text justify-content-center' },  
                {key: 'Sexo', label:"Sexo", sortable:true, tdClass:'', thClass:'table-header-text justify-content-center'}, 
                {key: 'Equipo', label:"Equipo", sortable:true, tdClass:'', thClass:'table-header-text justify-content-center'}, 
                {key:'Pos_Equipo', label:"Pos. Equipo", sortable:true, tdClass:'', thClass:'table-header-text py-0 my-0 justify-content-center'}
            ],
            participantes: [
        
            ],
            tableItems: [],
            favoriteItems:[],
            headOrder: [],
            puntosfields:['Punto', 'Tiempo', 'Hora', 'Posicion'],
            puntosInfo: [],
            horaPorPuntos: [],
            puntosIntermedios: [],
            criteriaFilter: 'a',
            teamCriteria: '',
            genderCriteria: '',
            categoryCriteria: '',
            participanteCriteria: '',
            participanteSearch:'',
            hombresCheck: true,
            mujeresCheck: true,
            equipoSelected: 'Todos',
            categoriaSelected: 'Todas',
            categorias:[],
            equipos:[],
            jsonFile: '',
            infoDeVueltas:[],
            queryPI: {Nombre:'META', PI:"META"},
            participantesPI5: [],
            participantesPI4: [],
            participantesPI3: [],
            participantesPI2: [],
            participantesPI1: [],
            participantesMeta: [],
            participantesInicio: [],
            selectedPoint:'Meta',
            filterStorage: [],
            menFilterOn: true,
            womenFilterOn: true,
            menFilterStatus: '2x',
            womenFilterStatus: '2x',
            favoriteDorsals: [],
            acumulativo: false,
            modalidadCarrera: null,
        }
    },
    methods: {
        addToFavorites(record){

            let $vm = this;
            
            if(record.Favorito){
                record.Favorito = false;
                $vm.favoriteItems.forEach( (favoriteItem, index, arr) => {
                    if(favoriteItem.Dorsal == record.Dorsal){
                        arr.splice(index, 1);
                    }
                })

                let indexToRemove = $vm.favoriteDorsals.indexOf(record.Dorsal);

                $vm.favoriteDorsals.splice(indexToRemove, 1);
                // console.log($vm.favoriteDorsals);
                document.cookie = this.$router.currentRoute.params.dir + "=" + JSON.stringify($vm.favoriteDorsals);
            }
            else{
                record.Favorito = true;
                // console.log(record);
                $vm.favoriteItems.push(record);
                $vm.favoriteDorsals.push(record.Dorsal);
                // console.log($vm.favoriteDorsals);
                document.cookie = this.$router.currentRoute.params.dir + "=" + JSON.stringify($vm.favoriteDorsals);
            }
            
        },
        filtroHombres(){
            let $vm = this;
            // console.log("Filtro para hombres");
            // Si el filtro para hombres es verdadero, entonces 

            if($vm.viewType == "Resultados"){
                $vm.handleResultsView($vm.queryPI);
            }
            else if($vm.viewType == "Cabeza"){
                $vm.setCabezaView();
                $vm.tableItems = $vm.headOrder;
            }
            else{

                $vm.setParticipantesView();
                $vm.handleResultsView({'Nombre':"INICIO", 'PI':'INICIO'});
                $vm.setParticipantesView();

            }

            if($vm.menFilterOn == true){
                if($vm.womenFilterOn == true){
                    $vm.genderCriteria = "F";
                }
                else{
                    $vm.genderCriteria = "";
                }
                $vm.menFilterStatus = "lg";
                $vm.menFilterOn = false;
            }
            else{
                if($vm.womenFilterOn == true){
                    $vm.genderCriteria = "MF";
                }
                else{
                    $vm.genderCriteria = "M";
                }
                $vm.menFilterStatus = "2x";
                $vm.menFilterOn = true;
            }

            let tempFilter = [];
            tempFilter = $vm.tableItems.filter( participante => this.filterTable(participante) )

            console.log(tempFilter.length)

            $vm.tableItems = tempFilter;

        },
        filtroMujeres(){
            let $vm = this;

            if($vm.viewType == "Resultados"){
                $vm.handleResultsView($vm.queryPI);
            }
            else if($vm.viewType == "Cabeza"){
                $vm.setCabezaView();
                $vm.tableItems = $vm.headOrder;
            }
            else{

                $vm.setParticipantesView();
                $vm.handleResultsView({'Nombre':"INICIO", 'PI':'INICIO'});
                $vm.setParticipantesView();

            }
            
            if($vm.womenFilterOn == true){
                if($vm.menFilterOn == true){
                    $vm.genderCriteria = "M";
                }
                else{
                    $vm.genderCriteria = "";
                }
                $vm.womenFilterStatus = "lg";
                $vm.womenFilterOn = false;
            }
            else{
                if($vm.menFilterOn == true){
                    $vm.genderCriteria = "MF";
                }
                else{
                    $vm.genderCriteria = "F";
                }
                $vm.womenFilterStatus = "2x";
                $vm.womenFilterOn = true;
            }

            let tempFilter = [];
            tempFilter = $vm.tableItems.filter( participante => this.filterTable(participante) )
            $vm.tableItems = tempFilter;
            // this.genderFilter();
        },
        hideTime(){
            let $vm = this;
            $vm.fields[3].tdClass = 'd-none';
            $vm.fields[3].thClass = 'd-none';
        },
        showTime(){
            let $vm = this;
            $vm.fields[3].tdClass = '';
            $vm.fields[3].thClass = 'table-header-text';
        },
        hideSplits(){
            let $vm = this;
            $vm.fields[4].tdClass = 'd-none';
            $vm.fields[4].thClass = 'd-none';
        },
        showSplits(){
            let $vm = this;
            $vm.fields[4].tdClass = '';
            $vm.fields[4].thClass = 'table-header-text';
        },
        handleResultsView(e){
            let $vm = this;
            console.log(e);
            // console.log($vm.participantes);
            let qualifiedRunners = [];
            $vm.queryPI = e;
            $vm.selectedPoint = e.PI;

            console.log($vm.selectedPoint);
            let vueltaRunnersInfo = [];
            let tempRunners = [];

            $vm.fields[5].tdClass = 'd-none';
            $vm.fields[5].thClass = 'd-none';

            // console.log($vm.selectedPoint);

            $vm.descripcionItems = "CLASIFICADOS";
            if(e.Nombre == "INICIO"){
                $vm.hideTime();
                $vm.hideSplits();
                qualifiedRunners = $vm.participantes.filter(participante => participante.Abandona != "True");
            }
            else if(e.Nombre != "META"){
                $vm.showTime();
                $vm.showSplits();
                if($vm.$store.getters.tipo != 'Vueltas' ){
                    qualifiedRunners = $vm.participantes.filter(participante => (participante.Info[e.PI] != "" && !isNaN(participante.Info[e.PI]) ));

                    // console.log(qualifiedRunners)
                }
                else{
                    vueltaRunnersInfo = $vm.infoDeVueltas.filter(participante => participante.NumeroVueltaT06 == e.PI)
                    // console.log(vueltaRunnersInfo);

                    vueltaRunnersInfo.forEach(runnerVuelta => {
                        // console.log(runnerVuelta)
                        try{
                            let tempArr = $vm.participantes.filter(participante => {
                                return participante.Info['IDParticipanteCarreraT05'] == runnerVuelta['IDParticipanteCarreraT06']
                            })
                            let tempObj = tempArr[0];
                            console.log( tempObj );
                            tempObj.Info[e.PI] = runnerVuelta.MiliSegundosVueltaT06;
                            tempObj['Acumulado'] = runnerVuelta.MiliSegundosT06;
                            tempRunners.push(tempObj);
                        }
                        catch(err){
                            console.log(err);
                        }
                    })
                    tempRunners.sort(function(a, b){
                        return a.Acumulado - b.Acumulado;
                    })
                    // console.log(tempRunners);
                    qualifiedRunners = tempRunners;
                }
            }
            else{
                $vm.showTime();
                $vm.hideSplits();
                // console.log("Se clickeo meta");
                // console.log($vm.participantes);
                qualifiedRunners = $vm.participantes.filter(participante => participante.LlegoMeta == true );
                
            }
            $vm.tableItems = qualifiedRunners;
            if( $vm.acumulativo == true ){

                $vm.tableItems.forEach( participante => {
                    if( participante.Info['MiliSegAccP1T05'] == null ){
                        participante.Info['MiliSegAccP1T05'] = participante.Info['MiliSegP1T05'];
                        
                    }
                    if( participante.Info['MiliSegAccP2T05'] == null ){
                        participante.Info['MiliSegAccP2T05'] = participante.Info['MiliSegP2T05'];
                        participante.Info['MiliSegP2T05'] = parseInt(participante.Info['MiliSegAccP2T05'] ) - parseInt( participante.Info['MiliSegAccP1T05'] );
                    }
                    if( participante.Info['MiliSegAccP3T05'] == null ){
                        participante.Info['MiliSegAccP3T05'] = participante.Info['MiliSegP3T05'];
                        participante.Info['MiliSegP3T05'] = parseInt(participante.Info['MiliSegAccP3T05'] ) - parseInt( participante.Info['MiliSegAccP2T05'] );
                    }
                    if( participante.Info['MiliSegAccP4T05'] == null ){
                        participante.Info['MiliSegAccP4T05'] = participante.Info['MiliSegP4T05'];
                        participante.Info['MiliSegP4T05'] = parseInt(participante.Info['MiliSegAccP4T05'] ) - parseInt( participante.Info['MiliSegAccP3T05'] );
                    }
                    if( participante.Info['MiliSegAccP5T05'] == null ){
                        participante.Info['MiliSegAccP5T05'] = participante.Info['MiliSegP5T05'];
                        participante.Info['MiliSegP5T05'] = parseInt(participante.Info['MiliSegAccP5T05'] ) - parseInt( participante.Info['MiliSegAccP4T05'] );
                    }

                } )
            }

            $vm.tableItems.forEach(participante => {
                // console.log(e.PI)
                if(e.PI == 'MiliSegP1T05'){

                    if( $vm.acumulativo == true ){

                        if( participante.Info['MiliSegAccP1T05'] == null ){
                            participante.Info['MiliSegAccP1T05'] = participante.Info['MiliSegP1T05'];
                        }

                    }

                    participante.Acumulado = parseInt(participante.Info['MiliSegP1T05']);
                    participante.PosCatPI = participante.Info['PosCatP1T05'];
                    participante.PosEqPI = participante.Info['PosEqP1T05'];
                }
                else if(e.PI == 'MiliSegP2T05'){

                    if( $vm.acumulativo == true){
                        // console.log(participante.split)
                        if( participante.Info['MiliSegAccP2T05'] == null ){
                            participante.Info['MiliSegAccP2T05'] = participante.Info['MiliSegP2T05'];
                        }
                        participante.Info['MiliSegP2T05'] = parseInt(participante.Info['MiliSegAccP2T05'] ) - parseInt( participante.Info['MiliSegAccP1T05'] );

                        // console.log(parseInt(participante.Info['MiliSegP2T05']))
                        // participante.Acumulado = parseInt(participante.Info['MiliSegP2T05']) - parseInt(participante.Info['MiliSegP1T05']);
                    }

                    participante.Acumulado = parseInt(participante.Info['MiliSegP1T05']);
                    participante.Acumulado += parseInt(participante.Info['MiliSegP2T05']);

                    participante.PosCatPI = participante.Info['PosCatP2T05'];
                    participante.PosEqPI = participante.Info['PosEqP2T05'];

                    // console.log(participante.PosCatPI)
                }
                else if(e.PI == 'MiliSegP3T05'){
                    if( $vm.acumulativo == true){
                        // console.log(participante.split)
                        // console.log(participante.Info['MiliSegP3T05'])
                        if( participante.Info['MiliSegAccP3T05'] == null ){
                            participante.Info['MiliSegAccP3T05'] = participante.Info['MiliSegP3T05'];
                        }
                        participante.Info['MiliSegP3T05'] = parseInt(participante.Info['MiliSegAccP3T05'] ) - parseInt( participante.Info['MiliSegAccP2T05'] );

                        // console.log(parseInt(participante.Info['MiliSegP2T05']))
                        // participante.Acumulado = parseInt(participante.Info['MiliSegP2T05']) - parseInt(participante.Info['MiliSegP1T05']);
                    }

                    participante.Acumulado = parseInt(participante.Info['MiliSegP1T05']);
                    participante.Acumulado += parseInt(participante.Info['MiliSegP2T05']);
                    participante.Acumulado += parseInt(participante.Info['MiliSegP3T05']);
                    participante.PosCatPI = participante.Info['PosCatP3T05'];
                    participante.PosEqPI = participante.Info['PosEqP3T05'];
                }
                else if(e.PI == 'MiliSegP4T05'){

                    if( $vm.acumulativo == true){
                        console.log(participante.split)
                        console.log(participante.Info['MiliSegP4T05'])
                        if( participante.Info['MiliSegAccP4T05'] == null ){
                            participante.Info['MiliSegAccP4T05'] = participante.Info['MiliSegP4T05'];
                        }
                        participante.Info['MiliSegP4T05'] = parseInt(participante.Info['MiliSegAccP4T05'] ) - parseInt( participante.Info['MiliSegAccP3T05'] );

                        // console.log(parseInt(participante.Info['MiliSegP2T05']))
                        // participante.Acumulado = parseInt(participante.Info['MiliSegP2T05']) - parseInt(participante.Info['MiliSegP1T05']);
                    }

                    participante.Acumulado = parseInt(participante.Info['MiliSegP1T05']);
                    participante.Acumulado += parseInt(participante.Info['MiliSegP2T05']);
                    participante.Acumulado += parseInt(participante.Info['MiliSegP3T05']);
                    participante.Acumulado += parseInt(participante.Info['MiliSegP4T05']);
                    participante.PosCatPI = participante.Info['PosCatP4T05'];
                    participante.PosEqPI = participante.Info['PosEqP4T05'];
                }
                else if(e.PI == 'MiliSegP5T05'){

                    if( $vm.acumulativo == true){
                        console.log(participante.split)
                        console.log(participante.Info['MiliSegP5T05'])
                        if( participante.Info['MiliSegAccP5T05'] == null ){
                            participante.Info['MiliSegAccP5T05'] = participante.Info['MiliSegP5T05'];
                        }
                        participante.Info['MiliSegP5T05'] = parseInt(participante.Info['MiliSegAccP5T05'] ) - parseInt( participante.Info['MiliSegAccP4T05'] );

                        // console.log(parseInt(participante.Info['MiliSegP2T05']))
                        // participante.Acumulado = parseInt(participante.Info['MiliSegP2T05']) - parseInt(participante.Info['MiliSegP1T05']);
                    }


                        participante.Acumulado = parseInt(participante.Info['MiliSegP1T05']);
                        participante.Acumulado += parseInt(participante.Info['MiliSegP2T05']);
                        participante.Acumulado += parseInt(participante.Info['MiliSegP3T05']);
                        participante.Acumulado += parseInt(participante.Info['MiliSegP4T05']);
                        participante.Acumulado += parseInt(participante.Info['MiliSegP5T05']);
                    participante.PosCatPI = participante.Info['PosCatP5T05'];
                    participante.PosEqPI = participante.Info['PosEqP5T05'];
                    // console.log("Punto 5");
                }

                else if(e.Nombre == 'META'){
                    participante.Acumulado = participante.Info.MiliSegCarreraT05;
                    participante.PosCatPI = participante.Info.PosCategoriaT05;
                    participante.PosEqPI = participante.Info.PosEquipoT05;
                    // console.log(participante.Info);
                }

                else if(e.PI == 'INICIO'){
                    participante.Acumulado = 0;
                    participante.PosCatPI = "";
                    participante.PosEqPI = "";
                }

                participante.tiempoPI = $vm.msToTime(participante.Acumulado);

                // console.log(participante.tiempoPI)
            })

            if(e.PI == 'INICIO'){
                $vm.fields[0].thClass = 'd-none';
                $vm.fields[0].tdClass = 'd-none';

                $vm.fields[7].thClass = 'd-none';
                $vm.fields[7].tdClass = 'd-none';

                $vm.fields[10].thClass = 'd-none';
                $vm.fields[10].tdClass = 'd-none';
                $vm.tableItems.sort(function(a, b){
                    return a.Dorsal - b.Dorsal;
                })
                // console.log("Se está consultando para inicio");
                // console.log($vm.tableItems);
            }
            else{
                $vm.fields[0].thClass = 'table-header-text justify-content-center';
                $vm.fields[0].tdClass = '';

                $vm.fields[7].thClass = 'table-header-text justify-content-center';
                $vm.fields[7].tdClass = '';

                $vm.fields[10].thClass = 'table-header-text justify-content-center';
                $vm.fields[10].tdClass = '';
                $vm.tableItems.sort(function(a, b){
                    return a.Acumulado - b.Acumulado;
                })
            }
            
            
        },
        setResultsView(){
            let $vm = this;

            $vm.queryPI = {"Nombre":'META', 'PI':'META'};

            $vm.fields[5].tdClass = '';
            $vm.fields[5].thClass = 'table-header-text';

            $vm.fields[0].tdClass = '';
            $vm.fields[0].thClass = 'table-header-text';

            $vm.fields[3].tdClass = '';
            $vm.fields[3].thClass = 'table-header-text';

            $vm.fields[7].tdClass = '';
            $vm.fields[7].thClass = 'table-header-text';

            $vm.fields[10].tdClass = '';
            $vm.fields[10].thClass = 'table-header-text';

            if($vm.viewType != "Resultados"){
                $vm.viewType = "Resultados";
                $vm.handleResultsView($vm.queryPI);
                $vm.$refs.pointSelectorInput.changeSelectedPoint($vm.queryPI);
                // $vm.getCarrera();
            }
        },

        setCabezaView(){
            let $vm = this;
            
            $vm.descripcionItems = "CLASIFICADOS"
            $vm.hideSplits();
            $vm.showTime();
            $vm.fields[5].tdClass = '';
            $vm.fields[5].thClass = 'table-header-text';

            $vm.fields[0].tdClass = '';
            $vm.fields[0].thClass = 'table-header-text';

            $vm.fields[3].tdClass = '';
            $vm.fields[3].thClass = 'table-header-text';

            $vm.fields[7].tdClass = '';
            $vm.fields[7].thClass = 'table-header-text';

            $vm.fields[10].tdClass = '';
            $vm.fields[10].thClass = 'table-header-text';

            $vm.selectedPoint = 'META';

            if($vm.viewType != "Cabeza"){
                $vm.viewType = "Cabeza";
                console.log("Ordenar por cabeza");
                console.log($vm.headOrder);
                $vm.tableItems = $vm.headOrder;

                if( $vm.acumulativo == true ){
                    console.log("Estamos aquí")
                    $vm.tableItems.forEach( participante => {
                        if( participante.Info['MiliSegAccP1T05'] == null ){
                            participante.Info['MiliSegAccP1T05'] = participante.Info['MiliSegP1T05'];
                            // participante.Info['PosCatPI'] = participante[]
                            participante.Info['posCatPI'] = participante.Info['PosCatP1T05'];
                        }
                        if( participante.Info['MiliSegAccP2T05'] == null ){
                            participante.Info['MiliSegAccP2T05'] = participante.Info['MiliSegP2T05'];
                            participante.Info['MiliSegP2T05'] = parseInt(participante.Info['MiliSegAccP2T05'] ) - parseInt( participante.Info['MiliSegAccP1T05'] );
                            participante.Info['posCatPI'] = participante.Info['PosCatP2T05'];
                        }
                        if( participante.Info['MiliSegAccP3T05'] == null ){
                            participante.Info['MiliSegAccP3T05'] = participante.Info['MiliSegP3T05'];
                            participante.Info['MiliSegP3T05'] = parseInt(participante.Info['MiliSegAccP3T05'] ) - parseInt( participante.Info['MiliSegAccP2T05'] );
                            participante.Info['posCatPI'] = participante.Info['PosCatP3T05'];
                        }
                        if( participante.Info['MiliSegAccP4T05'] == null ){
                            participante.Info['MiliSegAccP4T05'] = participante.Info['MiliSegP4T05'];
                            participante.Info['MiliSegP4T05'] = parseInt(participante.Info['MiliSegAccP4T05'] ) - parseInt( participante.Info['MiliSegAccP3T05'] );
                        }
                        if( participante.Info['MiliSegAccP5T05'] == null ){
                            participante.Info['MiliSegAccP5T05'] = participante.Info['MiliSegP5T05'];
                            participante.Info['MiliSegP5T05'] = parseInt(participante.Info['MiliSegAccP5T05'] ) - parseInt( participante.Info['MiliSegAccP4T05'] );
                        }

                    } )
                }
 
            }
        },
        setParticipantesView(){
            let $vm = this;
            // $vm.fields
            $vm.hideSplits();
            $vm.showTime();
            $vm.fields[5].tdClass = '';
            $vm.fields[5].thClass = 'table-header-text';
            $vm.selectedPoint = 'META';

            // if($vm.viewType != "Participantes"){
                $vm.viewType = "Participantes";
                // $vm.tableItems = $vm.headOrder;
                $vm.handleResultsView({'Nombre':"INICIO", 'PI':'INICIO'});
                $vm.fields[0].tdClass = 'd-none';
                $vm.fields[0].thClass = 'd-none';

                $vm.fields[3].tdClass = 'd-none';
                $vm.fields[3].thClass = 'd-none';

                $vm.fields[5].tdClass = 'd-none';
                $vm.fields[5].thClass = 'd-none';

                $vm.fields[7].tdClass = 'd-none';
                $vm.fields[7].thClass = 'd-none';

                $vm.fields[10].tdClass = 'd-none';
                $vm.fields[10].thClass = 'd-none';
                // $vm.getCarrera();
            // }

            if( $vm.acumulativo == true ){

            $vm.tableItems.forEach( participante => {
                if( participante.Info['MiliSegAccP1T05'] == null ){
                    participante.Info['MiliSegAccP1T05'] = participante.Info['MiliSegP1T05'];
                    
                }
                if( participante.Info['MiliSegAccP2T05'] == null ){
                    participante.Info['MiliSegAccP2T05'] = participante.Info['MiliSegP2T05'];
                    participante.Info['MiliSegP2T05'] = parseInt(participante.Info['MiliSegAccP2T05'] ) - parseInt( participante.Info['MiliSegAccP1T05'] );
                }
                if( participante.Info['MiliSegAccP3T05'] == null ){
                    participante.Info['MiliSegAccP3T05'] = participante.Info['MiliSegP3T05'];
                    participante.Info['MiliSegP3T05'] = parseInt(participante.Info['MiliSegAccP3T05'] ) - parseInt( participante.Info['MiliSegAccP2T05'] );
                }
                if( participante.Info['MiliSegAccP4T05'] == null ){
                    participante.Info['MiliSegAccP4T05'] = participante.Info['MiliSegP4T05'];
                    participante.Info['MiliSegP4T05'] = parseInt(participante.Info['MiliSegAccP4T05'] ) - parseInt( participante.Info['MiliSegAccP3T05'] );
                }
                if( participante.Info['MiliSegAccP5T05'] == null ){
                    participante.Info['MiliSegAccP5T05'] = participante.Info['MiliSegP5T05'];
                    participante.Info['MiliSegP5T05'] = parseInt(participante.Info['MiliSegAccP5T05'] ) - parseInt( participante.Info['MiliSegAccP4T05'] );
                }

            } )

            $vm.descripcionItems = "PARTICIPANTES"

        }

            // $vm.viewType = "Participantes";

        },
        closeInfo(){
            let $vm = this;
            $vm.$refs['runner-info'].hide();
        },
        teamFilter(){
            let $vm = this;

            if($vm.viewType == "Resultados"){
                $vm.handleResultsView($vm.queryPI);
            }
            else if($vm.viewType == "Cabeza"){
                $vm.setCabezaView();
                $vm.tableItems = $vm.headOrder;
            }
            else{
                $vm.setParticipantesView();
                $vm.handleResultsView({'Nombre':"INICIO", 'PI':'INICIO'});
                $vm.setParticipantesView();
            }

            if($vm.equipoSelected == 'Todos'){
                $vm.teamCriteria = '';
            }
            else{
                $vm.teamCriteria = $vm.equipoSelected;
            }

            let tempFilter = [];

            tempFilter = $vm.tableItems.filter( participante => $vm.filterTable(participante) );
            console.log(tempFilter.length)
            $vm.tableItems = tempFilter;
            
            // console.log($vm.teamCriteria);
        },
        genderFilter(){
            let $vm = this;

            if($vm.hombresCheck && $vm.mujeresCheck){
                $vm.genderCriteria = '';
            }
            else if($vm.hombresCheck){
                $vm.genderCriteria = 'M';
            }
            else if($vm.mujeresCheck){
                $vm.genderCriteria = 'F';
            }
            else{
                $vm.genderCriteria = 'MF';
            }

            if($vm.viewType == "Resultados"){
                $vm.handleResultsView($vm.queryPI);
            }
            else if($vm.viewType == "Cabeza"){
                $vm.setCabezaView();
                $vm.tableItems = $vm.headOrder;
            }
            else{
                $vm.setParticipantesView();
                $vm.handleResultsView({'Nombre':"INICIO", 'PI':'INICIO'});
                $vm.setParticipantesView();
            }

            if( $vm.$isMobile() ){

                let tempFilter = [];
                    if($vm.genderCriteria == 'M'){
                        tempFilter = $vm.tableItems.filter( participante => participante.Sexo == 'M' )
                    }
                    else{
                        tempFilter = $vm.tableItems.filter( participante => participante.Sexo == 'F' )
                    }
            }
            let tempFilter = [];

            tempFilter = $vm.tableItems.filter( participante => this.filterTable(participante) )

            $vm.tableItems = tempFilter;

            console.log($vm.tableItems.length)

        },
        categoryFilter(){
            let $vm = this;

                // if($vm.criteriaFilter == 'b'){
                //     $vm.criteriaFilter = 'a';
                // }
                // else{
                //     $vm.criteriaFilter = 'b';
                // }

            if($vm.viewType == "Resultados"){
                    $vm.handleResultsView($vm.queryPI);
                }
                else if($vm.viewType == "Cabeza"){
                    $vm.setCabezaView();
                    $vm.tableItems = $vm.headOrder;
                }
                else{
                    $vm.setParticipantesView();
                    $vm.handleResultsView({'Nombre':"INICIO", 'PI':'INICIO'});
                    $vm.setParticipantesView();

                }



            if($vm.categoriaSelected == 'Todas'){
                $vm.categoryCriteria = '';
            }
            else{
                $vm.categoryCriteria = $vm.categoriaSelected;
            }
            let tempFilter = []

            tempFilter = $vm.tableItems.filter( participante => this.filterTable( participante ) );
            console.log(tempFilter.length)
            // console.log(tempFilter);
            $vm.tableItems = tempFilter;
        },
        participanteFilter(){
            let $vm = this;
                // if($vm.criteriaFilter == 'b'){
                //     $vm.criteriaFilter = 'a';
                // }
                // else{
                //     $vm.criteriaFilter = 'b';
                // }

                if($vm.viewType == "Resultados"){
                    $vm.handleResultsView($vm.queryPI);
                }
                else if($vm.viewType == "Cabeza"){
                    $vm.setCabezaView();
                    $vm.tableItems = $vm.headOrder;
                }
                else{
                    $vm.setParticipantesView();
                    $vm.handleResultsView({'Nombre':"INICIO", 'PI':'INICIO'});
                    $vm.setParticipantesView();

                }

                $vm.participanteCriteria = $vm.participanteSearch.toUpperCase();

                let tempFilter = [];
                tempFilter = $vm.tableItems.filter( participante => this.filterTable(participante));
                console.log(tempFilter.length)
                // console.log(tempFilter);
                $vm.tableItems = tempFilter;
        },
        findUnique: function(value, index, self){
            return self.indexOf(value) === index;
        },
        filterTable: function(row){
            let $vm = this;
            if(row.Sexo.includes($vm.genderCriteria) || $vm.genderCriteria == '' || $vm.genderCriteria == 'MF'){
                if(row.Equipo.includes( $vm.teamCriteria )|| $vm.teamCriteria == ''){
                    if(row.Categoria.includes($vm.categoryCriteria) || $vm.categoryCriteria == ''){
                        if(row.Nombre.includes( $vm.participanteCriteria )
                            || row.Dorsal == $vm.participanteCriteria
                            || $vm.participanteCriteria == ''){
                            return true
                        }
                        else{
                            return false;
                        }
                    }
                    else{
                        return false;
                    }
                }
                else{
                    
                    return false
                }
            }
            else{
                return false;
            }
        },
        msToTime: function(s) {

            // Pad to 2 or 3 digits, default is 2
            function pad(n, z) {
                z = z || 2;
                return ('00' + n).slice(-z);
            }

            var ms = s % 1000;
            s = (s - ms) / 1000;
            var secs = s % 60;
            s = (s - secs) / 60;
            var mins = s % 60;
            var hrs = (s - mins) / 60;

        return pad(hrs) + ':' + pad(mins) + ':' + pad(secs) + '.' + pad(ms, 3);
        },
        getCarrera: function(_carrera, _TS){
            let carrera = _carrera;
            let TS = _TS;
            let $vm = this;
            // console.log("Solicitando info de la carrera " + carrera.toString());
            // console.log("Archivo " + TS.toString())
            let _url = '/data/' + carrera + '/' + TS + '_result.json.zip';
            let _url_vueltas = '';

            if( $vm.$store.getters.tipo == 'Vueltas'){
                _url_vueltas = '/data/' + carrera + '/vueltas_' + TS + '_result.json.zip';

                axios({
                    url: _url_vueltas,
                    method: 'GET',
                    responseType: 'blob'
                })
                .then( result =>{
                    let zipFile = result.data;
                    JSZip.loadAsync(zipFile)
                    .then(zip => {
                        let internalfile = Object.keys(zip.files)[0];
                        // console.log(internalfile);
                        zip.file(internalfile).async("string")
                        .then(result => {
                            let _parser = JSON.parse(result);

                            console.log( _parser );
                            $vm.infoDeVueltas = _parser;
                        })
                    })
                })
                .catch(err => {
                    console.log(err);
                })
                
            }

            axios({
                url: _url,
                method: 'GET',
                responseType: 'blob'
            })
            .then( result =>{
                let zipFile = result.data;
                JSZip.loadAsync(zipFile)
                .then(zip => {
                    
                    let internalfile = Object.keys(zip.files)[0];
                    // console.log(internalfile);
                    zip.file(internalfile).async("string")
                    .then(result => {
                        
                        let participantesInfo;
                        let categorias = [];
                        let equipos = [];
                        let _parser = JSON.parse(result);
                        
                        participantesInfo = _parser;

                        $vm.participantes = [];
                        
                        let now = new Date();
                        
                        participantesInfo.forEach( competidor => {
                            // console.log(element);                            
                            let _posCarrera = 'x';
                            let tiempoDeCarrera;
                            
                            if(competidor.PosCarreraT05 == ""){
                                competidor.PosCarreraT05= _posCarrera;
                            }

                            tiempoDeCarrera = this.msToTime(competidor.MiliSegCarreraT05)
                            competidor.tiempoTotal = tiempoDeCarrera;
                            if(competidor.VueltasCarreraT05 == ''){
                                competidor.VueltasCarreraT05 = '1'
                            }

                            let horaDeInicio = competidor.HoraInicioCarreraT05;
                            
                            if(competidor.HoraFinalCarreraT05 != '' || competidor.tiempoTotal != ''){
                                // console.log("Hay que recalcular el tiempo del competidor")
                            }
                            

                            let tiempo = '';
                            let llegoMeta = false;
                            if(competidor.tiempoTotal != '00:00:00.000'){
                                tiempo = competidor.tiempoTotal;
                                llegoMeta = true;
                                // console.log("Aquí estoy");
                            }
                            else{
                                // console.log("Aquí estoy");
                                if( $vm.estadoDeCarrera == "Corriendo" || $vm.estadoDeCarrera == "Finalizada" ){
                                    
                                    let _date = horaDeInicio.split(' ');
                                    let _fecha = _date[0];
                                    let _time = _date[1];
                                    
                                    let _day = _fecha.split('/')[0];
                                    let _month = _fecha.split('/')[1];
                                    let _year = _fecha.split('/')[2];
                                    
                                    let months = ["","Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

                                    _month = months[parseInt(_month)];
                                    // let formatedDate = _year + "-" + _month + "-" + _day + " " + _time + "+0200";
                                    // let formatedDate = _day + " " + _month + " " + _year + " " + _time + " " + "+0100";
                                    /**Cambiar el +0100 cuándo haya cambio de zona horaria*/
                                    let formatedDate = _day + " " + _month + " " + _year + " " + _time + " " + "+0200";
                                    // console.log(formatedDate);
                                    let initDate = Date.parse(formatedDate);
                                    // console.log(initDate);
                                    let _initDate = new Date(initDate);
                                    tiempo = this.restarTiempo(_initDate, now);

                                    if(competidor.AbandonaT05 == "True"){
                                        // console.log("Este competidor abandonó");
                                        tiempo = '--:--:--'
                                    }
                                }
                                else{
                                    // console.log("Estoy aquí porque la carrera no ha iniciado")
                                    tiempo = "00:00:00.000";
                                }
                                // console.log(tiempo);
                                
                            }

                            let avance = 0;
                            let acumulado = 0;
                            let nombrePI = "Inicio";
                            let lastPosCat = "";
                            let lastPosEq = "";

                            if(llegoMeta){
                                avance = 6;
                                acumulado = tiempoDeCarrera;
                                nombrePI = "Meta"
                                lastPosEq = competidor['PosEquipoT05'];
                            }
                            else if(competidor.MiliSegP5T05 != ""){
                                avance = 5;

                                    acumulado = parseInt(competidor['MiliSegP1T05']);
                                    acumulado += parseInt(competidor['MiliSegP2T05']);
                                    acumulado += parseInt(competidor['MiliSegP3T05']);
                                    acumulado += parseInt(competidor['MiliSegP4T05']);
                                    acumulado += parseInt(competidor['MiliSegP5T05']);
                                nombrePI = $vm.puntosIntermedios[4]?.Nombre;
                                lastPosCat = competidor['PosCatP5T05'];
                                lastPosEq = competidor['PosEqP5T05'];
                            }
                            else if(competidor.MiliSegP4T05 != ""){
                                avance = 4;

                                acumulado = parseInt(competidor['MiliSegP1T05']);
                                acumulado += parseInt(competidor['MiliSegP2T05']);
                                acumulado += parseInt(competidor['MiliSegP3T05']);
                                acumulado += parseInt(competidor['MiliSegP4T05']);
                                nombrePI = $vm.puntosIntermedios[3]?.Nombre;
                                lastPosCat = competidor['PosCatP4T05'];
                                lastPosEq = competidor['PosEqP4T05'];
                            }
                            else if(competidor.MiliSegP3T05 != ""){
                                avance = 3;

                                    acumulado = parseInt(competidor['MiliSegP1T05']);
                                    acumulado += parseInt(competidor['MiliSegP2T05']);
                                    acumulado += parseInt(competidor['MiliSegP3T05']);
                                nombrePI = $vm.puntosIntermedios[2]?.Nombre;
                                lastPosCat = competidor['PosCatP3T05'];
                                lastPosEq = competidor['PosEqP3T05'];
                                
                            }
                            else if(competidor.MiliSegP2T05 != ""){
                                avance = 2;

                                    acumulado = parseInt(competidor['MiliSegP1T05']);
                                    acumulado += parseInt(competidor['MiliSegP2T05']);
                                nombrePI = $vm.puntosIntermedios[1]?.Nombre;
                                lastPosCat = competidor['PosCatP2T05'];
                                lastPosEq = competidor['PosEqP2T05'];
                            }
                            else if(competidor.MiliSegP1T05 != ""){
                                avance = 1;
                                acumulado = parseInt(competidor['MiliSegP1T05']);
                                nombrePI = $vm.puntosIntermedios[0]?.Nombre;
                                lastPosCat = competidor['PosCatP1T05'];
                                lastPosEq = competidor['PosEqP1T05'];
                            }

                            if($vm.tipoDeCarrera == "Vueltas"){
                                nombrePI = "Vuelta "+competidor.VueltasCarreraT05;
                            }

                            let participanteObject = new Object();

                            participanteObject['Pos'] = competidor.PosCarreraT05;
                            participanteObject['Dorsal'] = competidor.DorsalT05;
                            participanteObject['Nombre'] = competidor.NombreT05 + " " + competidor.ApellidosT05;
                            participanteObject['Tiempo_Final'] = tiempo;
                            participanteObject['Categoria'] = competidor.NombreT10;

                            participanteObject['Sexo'] = competidor.SexoT05;
                            participanteObject['Pos_Categoria'] = competidor.PosCategoriaT05;
                            participanteObject['Equipo'] =  competidor.NombreT11;
                            participanteObject['Pos_Equipo'] = competidor.PosEquipoT05;
                            participanteObject['Info'] = competidor;
                            participanteObject['LlegoMeta'] = llegoMeta;
                            participanteObject['Abandona'] = competidor.AbandonaT05;
                            participanteObject['Acumulado'] = acumulado;
                            participanteObject['PI'] = avance;
                            participanteObject['NombrePI'] = nombrePI;
                            participanteObject['PosPI'] = lastPosEq;
                            participanteObject['UltimaPosCat'] = lastPosCat;
                            participanteObject['UltimaPosEq'] = lastPosEq;

                            if($vm.favoriteDorsals.length > 0){
                                if($vm.favoriteDorsals.includes(participanteObject['Dorsal'])){
                                    participanteObject['Favorito'] = true;
                                    $vm.favoriteItems.push(participanteObject);
                                    console.log("Push desde aquí");
                                }
                                else{
                                    participanteObject['Favorito'] = false;
                                }
                            }

                            $vm.participantes.push(participanteObject);
                            categorias.push(competidor.NombreT10);
                            equipos.push(competidor.NombreT11);
                        
                        })

                        // Creamos la información de los competidores en meta
                        $vm.participantesMeta = $vm.participantes.filter(participante => participante.PI == 6);
                        // Ordenamos para saber quien hizo el mejor tiempo
                        $vm.participantesMeta.sort(function(a,b) {return a.Info.MiliSegCarreraT05 - b.Info.MiliSegCarreraT05});
                        // Creamos la información de los competidores en el punto intermedio 5

                        $vm.participantesPI5 = $vm.participantes.filter(participante => participante.PI == 5);
                        $vm.participantesPI5.sort(function(a,b) {return a.Acumulado - b.Acumulado});

                        // Creamos la información de los competidores en el punto intermedio 4
                        $vm.participantesPI4 = $vm.participantes.filter(participante => participante.PI == 4);
                        $vm.participantesPI4.sort(function(a,b) {return a.Acumulado - b.Acumulado});

                        // Creamos la información de los competidores en el punto intermedio 3
                        $vm.participantesPI3 = $vm.participantes.filter(participante => participante.PI == 3);
                        $vm.participantesPI3.sort(function(a,b) {return a.Acumulado - b.Acumulado});

                        // console.log($vm.participantesPI3);
                        // Creamos la información de los competidores en el punto intermedio 2
                        $vm.participantesPI2 = $vm.participantes.filter(participante => participante.PI == 2);
                        $vm.participantesPI2.sort(function(a,b) {return a.Acumulado - b.Acumulado});

                        // Creamos la información de los competidores en el punto intermedio 1
                        $vm.participantesPI1 = $vm.participantes.filter(participante => participante.PI == 1);
                        $vm.participantesPI1.sort(function(a,b) {return a.Acumulado - b.Acumulado});

                        // Creamos la información de los competidores en el inicio
                        $vm.participantesInicio = $vm.participantes.filter(participante => participante.PI == 0);
                        $vm.participantesInicio.sort(function(a,b) {return a.Dorsal - b.Dorsal});

                        let tempArray = $vm.participantesMeta.concat($vm.participantesPI5);
                        
                        tempArray = tempArray.concat($vm.participantesPI4);
                        
                        tempArray = tempArray.concat($vm.participantesPI3);
                        
                        tempArray = tempArray.concat($vm.participantesPI2);
                        
                        tempArray = tempArray.concat($vm.participantesPI1);
                        
                        tempArray = tempArray.concat($vm.participantesInicio);
                        
                        // console.log(tempArray);
                        let index = 0;
                        tempArray.forEach(data => {
                            index += 1;
                            data.Pos_Cabeza = index;
                        });

                        $vm.headOrder = tempArray;

                        $vm.categorias = categorias.filter($vm.findUnique);
                        $vm.categorias.unshift("Todas");
                        // console.log($vm.categorias);

                        $vm.equipos = equipos.filter($vm.findUnique);
                        $vm.equipos.unshift("Todos");

                        if($vm.viewType == "Participantes"){
                            // $vm.tableItems = $vm.participantes;
                            $vm.tableItems = tempArray;
                        }
                        else if($vm.viewType == "Cabeza" ){
                            $vm.tableItems = tempArray;

                            if( $vm.acumulativo == true ){

                                $vm.tableItems.forEach( participante => {
                                    if( participante.Info['MiliSegAccP1T05'] == null ){
                                        participante.Info['MiliSegAccP1T05'] = participante.Info['MiliSegP1T05'];
                                        
                                    }
                                    if( participante.Info['MiliSegAccP2T05'] == null ){
                                        participante.Info['MiliSegAccP2T05'] = participante.Info['MiliSegP2T05'];
                                        participante.Info['MiliSegP2T05'] = parseInt(participante.Info['MiliSegAccP2T05'] ) - parseInt( participante.Info['MiliSegAccP1T05'] );
                                    }
                                    if( participante.Info['MiliSegAccP3T05'] == null ){
                                        participante.Info['MiliSegAccP3T05'] = participante.Info['MiliSegP3T05'];
                                        participante.Info['MiliSegP3T05'] = parseInt(participante.Info['MiliSegAccP3T05'] ) - parseInt( participante.Info['MiliSegAccP2T05'] );
                                    }
                                    if( participante.Info['MiliSegAccP4T05'] == null ){
                                        participante.Info['MiliSegAccP4T05'] = participante.Info['MiliSegP4T05'];
                                        participante.Info['MiliSegP4T05'] = parseInt(participante.Info['MiliSegAccP4T05'] ) - parseInt( participante.Info['MiliSegAccP3T05'] );
                                    }
                                    if( participante.Info['MiliSegAccP5T05'] == null ){
                                        participante.Info['MiliSegAccP5T05'] = participante.Info['MiliSegP5T05'];
                                        participante.Info['MiliSegP5T05'] = parseInt(participante.Info['MiliSegAccP5T05'] ) - parseInt( participante.Info['MiliSegAccP4T05'] );
                                    }

                                } )
                            }

                        }
                        else{
                            console.log($vm.queryPI);
                            $vm.handleResultsView($vm.queryPI);
                        }
                        // console.log($vm.equipos);
                    })
                    .catch(err => {
                        console.log("Error al abrir el archivo");
                        console.log(err);
                    })
                    
                })
                .catch(err =>{
                    console.log("Error descrompimiendo achivos")
                    console.log(err);
                })
            })
            .catch(err => {
                console.log(err);
            })
        },
        checkIfUpdate(dir){
            let $vm = this;
            let _url = '/data/' + dir + '/index.json';
            
            axios.get(_url)
                .then( response => {
                    // console.log($vm.jsonFile);
                    // console.log(response.data[0].TS);
                    if($vm.jsonFile != response.data[0].TS){
                        // console.log("Actualizar la tabla")
                        $vm.jsonFile = response.data[0].TS;
                        $vm.getCarrera(dir, $vm.jsonFile);
                        return true;
                    }
                    else{
                        // console.log("No hay nuevos datos");
                        return false;
                    }
                })
                .catch(err =>{
                    console.log(err);
                    return false;
                })
        },
        getCarreraInfo(dir){
            let $vm = this;
            let _url = '/data/' + dir + '/index.json';
            
            axios.get(_url)
                .then( response => {
                    let carreraData = response.data[0];
                    console.log( response.data[0] );

                    $vm.carreraName = carreraData.NombreCarreraT01;
                    // $vm.tipoPuntosIntermedios = carreraData.
                    $vm.vueltas = carreraData.NumeroVueltasPI;

                    // if( $vm.carreraName.toLowerCase().includes("V SENDAS DE CUENCA 2022 - MARATÓN".toLowerCase())
                    //     || $vm.carreraName.toLowerCase().includes("XXIV MARCHA BTT MONTES DE REQUENA 2022 - ELECTRICA".toLowerCase())
                    //     || $vm.carreraName.toLowerCase().includes("XXIV MARCHA BTT MONTES DE REQUENA 2022".toLowerCase())
                    //     || $vm.carreraName.toLowerCase().includes("IX Ruta BTT Ciudad de Huete - LARGO".toLowerCase()) ){
                    //     $vm.acumulativo = true;
                    //     console.log( "Es carrera acumulativa" );
                    // }

                    if( carreraData.TipoPuntosIntermedios == "Puntos Intermedios Separados" || carreraData.TipoPuntosIntermedios == "Puntos Intermedios" ) {
                        $vm.acumulativo = true;
                        // console.log( "Es carrera acumulativa" );
                    }
                    // Guardamos el estado de la carrera, si aún no ha finalizado,
                    // debemos activar el temporizador para empezar a aumentar el tiempo de
                    // cada participante
                    $vm.estadoDeCarrera = carreraData.EstadoCarrera;
                    $vm.tipoDeCarrera = carreraData.TipoCarrera;

                    $vm.modalidadCarrera = carreraData.Modalidad;
                    console.log($vm.estadoDeCarrera);

                    if( $vm.estadoDeCarrera == "Listado inicial"){
                        $vm.viewType = "Participantes"
                    }
                    else if( $vm.estadoDeCarrera == "Corriendo" ){
                        $vm.viewType = "Cabeza"
                    }
                    else if( $vm.estadoDeCarrera == "Finalizada"){
                        $vm.viewType = "Resultados"
                    }

                    if($vm.vueltas == ''){
                        $vm.vueltas = '1';
                    }

                    $vm.$store.commit('setModalidad', response.data[0].Modalidad);
                    $vm.$store.commit('change', $vm.carreraName);
                    $vm.$store.commit('changeTipo', response.data[0].TipoCarrera);
                    $vm.$store.commit('changeDistancia', response.data[0].DistanciaMetrosTotalT01);
                    $vm.$store.commit('numeroDePuntosIntermedios', response.data[0].PuntosIntermediosT01);
                    $vm.$store.commit('numeroDeVueltas', response.data[0].NumeroVueltasPI);

                    $vm.puntosIntermedios = response.data[0].NombresPI.split(';');

                    let puntosIntermediosObject = [];
                    let vueltasObject = [];

                    for(let puntoIndex = 0; puntoIndex < response.data[0].PuntosIntermediosT01; puntoIndex++){
                        puntosIntermediosObject.push({'Nombre':$vm.puntosIntermedios[puntoIndex], 'PI':'MiliSegP' + (puntoIndex +1).toString() + "T05"});
                    }

                    $vm.puntosIntermedios = puntosIntermediosObject;

                    // console.log(puntosIntermediosObject);
                    $vm.$store.commit('puntosIntermedios', puntosIntermediosObject);

                    if($vm.tipoDeCarrera == 'Vueltas'){
                        for(let vueltaIndex = 0; vueltaIndex < response.data[0].NumeroVueltasPI; vueltaIndex++){
                            let indexPrefix = (vueltaIndex + 1).toString();
                            vueltasObject.push({'Nombre':'V'+ indexPrefix, 'PI':vueltaIndex + 1});
                        }
                    }
                    $vm.$store.commit('Vueltas', vueltasObject);

                    // console.log($vm.puntosIntermedios)
                    $vm.jsonFile = response.data[0].TS;
                    $vm.getCarrera(dir, $vm.jsonFile);
                })
                .catch(err => {
                    console.log(err);
                })
        },
        restarTiempo: function(startTime, currentTime){

            let difference = currentTime.getTime() - startTime.getTime();
            let newDate = this.msToTime(difference);
            return newDate;
        },
        sumTime: function(startTime, timeLapse){
            // La posición 0 representa horas, la posición 1 representa
            // los minutos y la posición 2 representa los segundos
            let initTimeInfo = startTime.split(":");
            // La posición 0 representa horas, la posición 1 representa
            // los minutos y la posición 2 representa los segundos
            let timeLapseInfo = timeLapse.split(":");

            let initTimeHours = parseInt(initTimeInfo[0]);
            let initTimeMinutes = parseInt(initTimeInfo[1]);
            let initTimeSeconds = parseInt(initTimeInfo[2]);

            let timeLapseHours = parseInt(timeLapseInfo[0]);
            let timeLapseMinutes = parseInt(timeLapseInfo[1]);
            let timeLapseSeconds = parseInt(timeLapseInfo[2]);

            // Calculamos el valor total de los segundos que
            // transcurrieron
            let seconds = initTimeSeconds + timeLapseSeconds;
            // Calculamos los minutos, sumando las veces que los segundos pasaron
            // de 60;
            let minutes = initTimeMinutes + timeLapseMinutes + seconds/60;
            minutes = Math.floor(minutes);
            let hours = initTimeHours + timeLapseHours + minutes / 60;
            hours = Math.floor(hours);
            seconds = seconds % 60;
            minutes = minutes % 60;

            hours = String(hours);
            minutes = String(minutes);
            seconds = String(seconds);

            if(hours.length < 2){
                hours = "0" + hours;
            }
            if(minutes.length < 2){
                minutes = "0" + minutes;
            }
            if(seconds.length < 2){
                seconds = "0" + seconds;
            }
            return (hours + ":" + minutes + ":" + seconds);
        },
        participanteSelected: function(record, index){
            let $vm = this;
            console.log(record);
            $vm.position = record.Pos;
            $vm.runner = record.Nombre;
            $vm.runnerDorsal = record.Dorsal;
            $vm.runnerTeam = record.Equipo;
            $vm.runnerModalidad = record.Info.CodigoT11;
            $vm.runnerCategory = record.Info.NombreT10;
            $vm.runnerFinalTime = record.Tiempo_Final;
            $vm.runnerPositionTeam = record.Pos_Equipo;
            $vm.runnerInfo = record.Info;
            $vm.runnerTermino = record.LlegoMeta;

            let horaDeInicio = $vm.runnerInfo.HoraInicioCarreraT05;
            // Dividimos el valor de la Hora de inicio de carrera, para
            // separar la fecha de la hora.
            horaDeInicio = horaDeInicio.split(" ")[1];

            $vm.runnerMilisencods = record.Info.MiliSegCarreraT05;

            console.log( this.runnerMilisencods );

            console.log( record.Info );

            // $vm.horaInicio = record.Info.HoraInicioCarreraT05;
            console.log("El correcor inicio a las: " + horaDeInicio);

            this.$refs['runner-info'].show();
            
            // console.log($vm.puntosIntermedios.length);
            
            // console.log($vm.runnerInfo);
            console.log(record);
            console.log(`Tipo de carrera: ${$vm.tipoDeCarrera}`)

            $vm.puntosInfo = [];
            $vm.puntosInfo.push({
                Punto: "Inicio",
                Tiempo: "00:00:00.00",
                Hora: $vm.runnerInfo.HoraInicioCarreraT05.split(' ')[1],
                Posicion: ''
            })

            if($vm.tipoDeCarrera == "Vueltas"){
                console.log($vm.infoDeVueltas);
                let vueltasCorredor = $vm.infoDeVueltas.filter(info => 
                info.IDParticipanteCarreraT06 == $vm.runnerInfo.IDParticipanteCarreraT05);
                
                // console.log(vueltasCorredor);
                vueltasCorredor.forEach(vuelta => {
                    $vm.puntosInfo.push({
                        Punto: "Vuelta " + vuelta.NumeroVueltaT06,
                        Tiempo: $vm.msToTime(vuelta.MiliSegundosVueltaT06),
                        Hora: $vm.sumTime($vm.runnerInfo.HoraInicioCarreraT05.split(' ')[1], $vm.msToTime(vuelta.MiliSegundosT06)) ,
                        Posicion: ''
                    })
                })
            }
            else if($vm.tipoDeCarrera == "Simple"){
                $vm.puntosInfo.push({
                    Punto: 'Meta',
                    Tiempo: $vm.runnerFinalTime,
                    Hora: $vm.runnerInfo.HoraFinalCarreraT05.split(' ')[1],
                    Posicion: $vm.runnerInfo['PosCarreraT05']
                })
            }
            else{
                // Esto se hace cuando solo hay un punto intermedio y una vuelta
                // console.log($vm.puntosIntermedios);
                if($vm.puntosIntermedios.length == 0 && $vm.vueltas == '1'){
                    if($vm.puntosIntermedios[0].Nombre == ''){
                        $vm.puntosInfo.push({
                            Punto: 'Meta',
                            Tiempo: $vm.runnerFinalTime,
                            Hora: $vm.runnerInfo.HoraFinalCarreraT05.split(' ')[1],
                        })
                    }
                }
                // En caso de que se encuentren muchos puntos intermedios, se hará lo siguiente
                else{
                    for(let index = 0; index < $vm.puntosIntermedios.length; index++){

                        let _index = index + 1;
                        let puntoName = null;
                        let puntoNameTime = null;
                        if(this.acumulativo){
                            puntoName = 'MiliSegAccP' + _index.toString() + 'T05';
                            puntoNameTime = 'MiliSegP' + _index.toString() + 'T05'
                        }
                        else{
                            puntoName = 'MiliSegP' + _index.toString() + 'T05';
                            puntoNameTime = puntoName
                        }
                        let posCarrName = 'PosCarrP' + _index.toString() + 'T05';
                        let ms = $vm.runnerInfo[puntoName];
                        let ms2 = $vm.runnerInfo[puntoNameTime]
                        let hora = "";
                        let _tiempoFinal = "";
                        let _tiempoInicial = "";
                        
                        console.log($vm.runnerInfo)
                        let _time = ms ? $vm.msToTime(ms) : "-";
                        let _time2 = ms ? $vm.msToTime(ms2) : "-";
                        let horaDeLlegada = "";
                        // console.log($vm.puntosInfo);
                        if(index > 0){
                            // console.log($vm.puntosInfo[index-1])
                            hora = $vm.puntosInfo[_index-1].Hora;
                            horaDeLlegada = $vm.sumTime(hora, _time2);
                        }
                        else{
                            hora = $vm.runnerInfo.HoraInicioCarreraT05;
                            hora = hora.split(" ")[1];
                            horaDeLlegada = $vm.sumTime(hora, _time2);
                        }
                        
                        if(_time == "00:00:00.000" && index > 0){
                            $vm.puntosInfo.push({
                                Punto: $vm.puntosIntermedios[index].Nombre,
                                Tiempo: '-',
                                Hora: '-',
                                Posicion: ''
                            })    
                        }
                        else{
                            console.log(_time)
                            $vm.puntosInfo.push({
                            Punto: $vm.puntosIntermedios[index].Nombre,
                            Tiempo: _time,
                            Hora: _time != '-' ? horaDeLlegada : "-",
                            Posicion: $vm.runnerInfo[posCarrName]
                        })
                        }
                        
                    }

                    if($vm.runnerTermino){
                        console.log($vm.$store.getters.numeroDePI);
                        let _totalTimePi = 0;
                        for(index = 1; index <= $vm.$store.getters.numeroDePI; index++){
                            let infoDePi = 'MiliSegP' + index.toString() + 'T05';
                            _totalTimePi += parseInt($vm.runnerInfo[infoDePi]);
                            console.log(_totalTimePi);
                        }

                        let _totalTime = parseInt($vm.runnerInfo.MiliSegCarreraT05);
                        // console.log(_totalTime);
                        // let timeDif = _totalTime - _totalTimePi;
                        let _time = $vm.msToTime(_totalTime);
                        // console.log(timeDif);
                        console.log(_time);
                        $vm.puntosInfo.push({
                            Punto: 'Meta',
                            Tiempo: _time,
                            Hora: $vm.runnerInfo.HoraFinalCarreraT05.split(' ')[1],
                            Posicion: $vm.position
                        })
                    }
                    else{
                        $vm.puntosInfo.push({
                            Punto: 'Meta',
                            Tiempo: '-',
                            Hora: '-',
                            Posicion: ''
                        })
                    }
                    
                }
            }
        //    console.log(record);
            
        }
    },
    created(){

        // console.log("Creando web");
        this.carreraDir = this.$router.currentRoute.params.dir;

        this.viewType = "Resultados"
        this.setResultsView();

        this.getCarreraInfo(this.carreraDir);
        let secondsCounter = 0;

        this.viewType = "Cabeza"
        this.setCabezaView();

        let nameEQ = this.carreraDir +"=";
        
        let decodedCookie = decodeURIComponent(document.cookie);
        
        let ca = decodedCookie.split(";");
        let tempString = '';
        for(var i = 0; i < ca.length; i++){
            var c = ca[i];

            while(c.charAt(0) == ' ') c = c.substring(1, c.length);

            if (c.indexOf(nameEQ) == 0) {
                tempString = decodeURIComponent( c.substring(nameEQ.length,c.length) );
            }
        }
        try{
            this.favoriteDorsals = JSON.parse(tempString);
        }
        catch(error) {
            console.log(error)
        }
        
        // console.log("Iniciando temporizador");
        // Iniciamos un temporizador si la carrera aún no termina.
        setInterval(()=> {
            let $vm = this;
            secondsCounter += 1;
            // console.log(secondsCounter);
            if($vm.estadoDeCarrera == "Corriendo"){

                
                // console.log("segundos transcurridos: " + secondsCounter)
                $vm.participantes.forEach( participante => {
                    if(participante.LlegoMeta == false){
                        
                        if(participante.Abandona != "True"){
                            participante.Tiempo_Final = this.sumTime(participante.Tiempo_Final.split('.')[0], 
                            "00:00:01");
                            // console.log(participante.Tiempo_Final);
                            participante.Tiempo_Final += ".000";    
                        }
                    }
                    
                })
            }
            else if($vm.estadoDeCarrera == "Finalizada"){
                // console.log("Esta carrera ya terminó");
            }
            // Activamos cada 10 segundos el refresco de la página
            
            if( !(secondsCounter % 2) ){
                // console.log("Actualizar la tabla: " + $vm.estadoDeCarrera);
                // console.log($vm.estadoDeCarrera);
                if($vm.estadoDeCarrera == "Corriendo"){
                    let newUpdate = this.checkIfUpdate(this.carreraDir);
                }
                else{
                    // console.log("La carrera ya terminó");
                }
            }
        }, 1000)
    },
    computed: {
        tableItemsXLS(){
            let $vm = this;
            
            let tableXLS = [];

            $vm.tableItems.forEach( item => {
                let xlsItem = new Object();

                xlsItem['Posición'] = item.Pos;
                xlsItem['Dorsal'] = item.Dorsal;
                xlsItem['Nombre'] = item.Nombre;
                xlsItem['Tiempo final'] = item.Tiempo_Final;
                xlsItem['Categoría'] = item.Categoria;
                xlsItem['Sexo'] = item.Sexo;
                xlsItem['Posición por categoría'] = item.Pos_Categoria;
                xlsItem['Equipo'] = item.Equipo;
                xlsItem['Posición por Equipo'] = item.Pos_Equipo;

                $vm.puntosIntermedios.forEach( punto => {
                    xlsItem[punto.Nombre] = $vm.msToTime(item.Info[punto.PI]);
                })
                tableXLS.push(xlsItem);
            })
            // tableXLS = $vm.tableItems;
            // console.log($vm.puntosIntermedios);
            // console.log(tableXLS);
            return tableXLS;
        },
        showParticipantesInfo(){
            let $vm = this;
            if($vm.viewType == 'Participantes'){
                return true;
            }
            else{
                return false;
            }
        },
        showExtraInfo(){
            let $vm = this;
            if($vm.viewType == 'Resultados'){
                if($vm.selectedPoint == 'META' || $vm.selectedPoint == 'INICIO') {
                    return true;
                }
                else{
                    return false;
                }
            }
            else{
                return true;
            }
        },
        subtotal(){
            let $vm = this;
            return $vm.tableItems.length;
        },
        total(){
            let $vm = this;
            return $vm.participantes.length;
        },
        _updatedTableItems(){
            let $vm = this;
            return $vm.tableItems;
        },
        _puntosIntermedios(){
            
            let $vm = this;
            return $vm.$store.getters.numeroDePI;
        },
        rows() {
            return this.participantes.length
        },
        runnerRitme() {
            let $vm = this;
            console.log($vm.modalidadCarrera);
            console.log( this.runnerMilisencods );

            if( $vm.modalidadCarrera != "A PIE" && $vm.modalidadCarrera != "A PIE (TRAIL/RUNNING)"){

                let sTotal = parseFloat( $vm.runnerMilisencods) / 1000;

                console.log( $vm.runnerMilisencods );
                // let duration = (3600.0 * ($vm.$store.getters.distancia / 1000)) / (parseFloat($vm.runnerMilisencods) / 1000);
                let duration = (3600.0 * ($vm.$store.getters.distancia / sTotal )) / 1000;

                let ritmo = duration.toFixed(3)
                // console.log(duration.toFixed(3));
                if(ritmo == 'NaN'){
                    // console.log("Es un NaN")
                    return '--km/h'
                }
                return duration.toFixed(3) + "km/h";

            }
            else{
                let sTotal = parseFloat( $vm.runnerMilisencods) / 1000;
                let duration = (3600.0 * ($vm.$store.getters.distancia / sTotal )) / 1000;
                let ritmo = duration;
                // console.log(duration.toFixed(3));
                if(ritmo == 'NaN'){
                    // console.log("Es un NaN")
                    return '--min/km'
                }

                let ritmoConverted = 60 / duration;

                console.log( ritmoConverted );

                let ritmoFormated = ritmoConverted;

                const _duration = moment.duration( ritmoFormated * 60000 );
                const tempTime = moment.utc( _duration.as('milliseconds') )
                
                // console.log( tempTime) 
                try{
                    console.log( tempTime.format('mm:ss') );
                }
                catch(err){
                    console.log( err );
                }
                // return ritmoFormated.replace(".", ":") + "min/Km";
                // return `${tempTime.minutes()}:${tempTime.seconds()}min/Km`
                return `${tempTime.format('mm:ss')}min/Km`
            }
        },
        carrera(){
            return this.$store.getters.carrera;
        }
    }
}
</script>
