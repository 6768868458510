import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        carrera: '',
        distancia: '',
        tipo: '',
        modalidad: '',
        numeroDeVueltas: '',
        vueltas:'',
        numeroDePuntosIntermedios: '',
        puntosIntermedios: ''
    },
    mutations: {
        puntosIntermedios(state, puntosIntermedios){
            state.puntosIntermedios = puntosIntermedios;
        },
        numeroDePuntosIntermedios(state, numeroDePI){
            state.numeroDePuntosIntermedios = numeroDePI;
        },
        numeroDeVueltas(state, numeroDeVueltas){
            state.numeroDeVueltas = numeroDeVueltas;
        },
        change(state, carrera){
            state.carrera = carrera;
        },
        changeDistancia(state, distancia){
            state.distancia = distancia;
        },
        changeTipo(state, tipo){
            state.tipo = tipo;
        },
        setModalidad(state, modalidad){
            state.modalidad = modalidad;
        },
        Vueltas(state, vueltas){
            state.vueltas = vueltas
        }
    },
    getters:{
        carrera: state => state.carrera,
        distancia: state => state.distancia,
        tipo: state => state.tipo,
        modalidad: state => state.modalidad,
        numeroDePI: state => state.numeroDePuntosIntermedios,
        puntosIntermedios: state => state.puntosIntermedios,
        numeroDeVueltas: state => state.numeroDeVueltas,
        vueltas: state => state.vueltas
    }
})