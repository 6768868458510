<template>
    <div class="LayoutDefault container-fluid px-0">
        <b-navbar class="Timing-Header">
            <b-navbar-brand href="#">
                <img class="crono-logo" alt="cronomancha logo" src="../assets/Timing Logo.png">
            </b-navbar-brand>
            <b-navbar-nav class="">

            <h2 class="font-weight-bold text-title ms-1 d-none d-md-inline-block">{{carrera}}</h2>
            <h4 class="font-weight-bold text-title ms-1 me-0 d-inline-block d-md-none">{{carrera}}</h4>
                
            </b-navbar-nav>
            <b-navbar-nav class="ms-auto me-4">
                <b-button style="background-color: #f80606" v-on:click="goHome">Inicio</b-button>
            </b-navbar-nav>
        </b-navbar>
        <!-- <div class="d-flex banner-container"> -->
            <!-- <img src="../assets/marathon.jpg" class="crono-banner"/> -->
            <!-- <h2 class="font-weight-bold text-title mx-auto d-none d-md-inline-block">{{carrera}}</h2>
            <h4 class="font-weight-bold text-title mx-auto d-inline-block d-md-none">{{carrera}}</h4> -->

        <!-- </div> -->
        <!-- <div class="container-fluid mx-0 px-0">
            <div class="box">
                <img src="../assets/marathon.jpg" class="crono-banner"/>
                <h3 class="font-weight-bold text-title">Sistema de resultados en tiempo real</h3>
            </div>
        </div> -->
        
        <main class="LayoutDefault__main">
            <slot>

            </slot>
        </main>
        <footer class="LayoutDefault__footer">
            &copy; cronomancha
        </footer>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    setup() {
        
    },
    methods: {
        goHome: function(){
            let $vm = this;
            $vm.$router.push('/');
        }
    },
    computed: {
        // Regresa el nombre de la carrera, directo desde el store
        carrera() {
            return this.$store.getters.carrera
        }
    }
})
</script>
